import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import _ from 'lodash'; // setClients(prevState => _.reject(prevState, { token: client.token }));
// import {OrganizationContext} from "../../Shared/OrganizationContext.js";
// import {UserContext} from "../../Shared/UserContext.js";
import "../../Shared/react-tabs.css";
const Index = ({organization_id, project_id}) => {
  // const {organizationAuthorizations} = useContext(OrganizationContext);
  // const currentUser = useContext(UserContext);
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [clientOnboardings, setClientOnboardings] = useState([]);

  useEffect(() => {
    fetchClientOnboardingReads();
  }, [organization_id, project_id]);

  const fetchClientOnboardingReads = () => {
    axios.get(`/api/o/${organization_id}/client_onboardings.json?project_id=${project_id}`)
    .then(function(response){
    //   console.log(response);
      if(response.data.success){
        setClientOnboardings(response.data.client_onboardings);
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.post(`/api/`)
    .then(function(response){
      // console.log(response);
      if(response.data.success){

      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const client_onboardings_list = clientOnboardings.map((client_onboarding) => {
    const client_onboarding_reads = client_onboarding.client_onboarding_reads.map((client_onboarding_read) => {
      return(
        <div class="panel panel-default pb-0">
          <div class="panel-heading">
            {client_onboarding.kind === "File" && 
              <div className="display-flex justify-content-between cursor-pointer flex-direction-column width-100-percent ml-15-negative mr-15-negative">
                {client_onboarding_read.uploads.map((upload) => {
                  return(
                    <a href={upload.url} target="_blank" className="color-1 display-flex align-items-center justify-content-between border-bottom pt-md-2 pb-md-2 opacity-6 opacity-10-hover">
                      <div className="font-weight-bold">{upload.filename}</div>
                      <i className="fal fa-link font-10"></i>
                    </a>
                  )
                })}
              </div>
            }

            {client_onboarding.kind === "Video" && 
              <>
                <div>
                  Viewed on <strong>{client_onboarding_read.created_at}</strong>
                </div>
              </>
            }

            {!client_onboarding.kind && 
             <div>
                Viewed on <strong>{client_onboarding_read.created_at}</strong>
            </div>
            }

            {client_onboarding.kind === "Comments" && 
             <div>
                Viewed on <strong>{client_onboarding_read.created_at}</strong>
            </div>
            }

            <div dangerouslySetInnerHTML={{ __html: client_onboarding_read.comment }} />
          </div>
          
          <div className="opacity-4 font-12 mt-8 pl-15">Responded by {client_onboarding_read.user.username}</div>
        </div>
      )
    })

    return(
      <div class="panel panel-default">
        <div class="panel-heading cursor-pointer display-flex justify-content-between align-items-center background-hover border-radius" data-toggle="collapse" data-parent="#accordion" href={`#collapse${client_onboarding.token}`}>
          <h4 class="panel-title">
            <i className={`fal ${client_onboarding.client_onboarding_reads.length > 0 ? "fa-check-circle color-green" : "fa-circle opacity-5"} mr-8`}></i>
            <a>
              {client_onboarding.title}
            </a>

          </h4>
          <i className="fal fa-chevron-right"></i>
        </div>
        <div id={`collapse${client_onboarding.token}`} class="panel-collapse collapse">
          <div class="panel-body">
            {client_onboarding.client_onboarding_reads.length > 0 ? (
              client_onboarding_reads
            ) : (
              <div className="display-flex justify-content-center align-items-center opacity-4">
                Incomplete
              </div>
            )}
          </div>
        </div>
      </div>
    )
  })

  return(
    <React.Fragment>
      <div class="panel-group" id="accordion">
        {client_onboardings_list}
      </div>
    </React.Fragment>
  )
}

export default Index;