import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import _ from 'lodash'; // setClients(prevState => _.reject(prevState, { token: client.token }));
// import {OrganizationContext} from "../../Shared/OrganizationContext.js";
// import {UserContext} from "../../Shared/UserContext.js";
import EmbeddableView from "./EmbeddableView.js";
import SelectProject from "../Shared/SelectProject.js";
import EmbedForm from "../Apps/Embeds/EmbedForm.js";
import { ProjectContext } from "../Projects/ProjectContext.js";

const EmbeddableItem = ({embeddable, closeModal}) => {
  // const organizationAuthorizations = useContext(OrganizationContext);
  // const currentUser = useContext(UserContext);
  const { fetchProjects } = useContext(ProjectContext);
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [openProjects, setOpenProjects] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [openEmbed, setOpenEmbed] = useState(false);


  return(
    <React.Fragment>
      <div className="col-6 col-sm-4 padding-10">
        <div className="app-integration-box default-padding border-all border-radius box-shadow-light" style={{height: "100%"}}>
          <div className="mb-5 display-row space-between aic">
            <img src={embeddable.preview_image} className="avatar border-radius mr-8" />
            <div className="font-14 font-600 color-black-always">{embeddable.title}</div>
          </div>
          <div className="font-12 opacity-6 mt-15">{embeddable.description}</div>

          <div className="mt-15 width-100-percent text-right  ">
            <a onClick={() => setOpen(true)} className="btn btn-small background-hover color-1"><i className="fal fa-eye mr-8"></i>View</a>
            <a onClick={() => {match.params.project_id ? setOpenEmbed(true) : setOpenProjects(true)}} className="btn btn-small border-all background-hover color-1"><i className="fal fa-plus mr-8"></i>Add</a>
          </div>
        </div>
      </div>


      <Modal open={open} onClose={() => setOpen(false)} className="modal-body-white" classNames={{modal: 'embed-modal-view'}} center>
        <div className="row no-margin">
          <EmbeddableView embeddable={embeddable} callToAction={() => {match.params.project_id ? setOpenEmbed(true) : setOpenProjects(true)}}/>
        </div>
      </Modal>

      <Modal open={openProjects} onClose={() => setOpenProjects(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
        <div className="row no-margin">
          <div className="field">
            <SelectProject selectedProject={selectedProject} setSelectedProject={setSelectedProject} closeModal={() => {setOpenProjects(false); setOpenEmbed(true)}}/>
          </div>
        </div>
      </Modal>

      <Modal open={openEmbed} onClose={() => setOpenEmbed(false)} className="modal-body-white" classNames={{modal: 'modal-full-width'}} center>
        <EmbedForm project_id={match.params.project_id || selectedProject?.value} closeModal={() => {setOpenEmbed(false); closeModal(); }} refetchData={fetchProjects}/>
      </Modal>
    </React.Fragment>
  )
}

export default EmbeddableItem;