import React from 'react';
import Linkify from 'react-linkify';

const TextInput = ({ field, handleFieldChange, removeField, handleLabelChange, taskForm, handleValueChange }) => {
  return (
    <React.Fragment>
      <div className="field task-form-left-side">
        <div className="mb-10">
          <div className="display-flex gap-8">
            <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
              <a href={decoratedHref} key={key} target="_blank">{decoratedText}</a>
            )}>
              {field.label}
            </Linkify>
            {field.isRequired ? <span className="color-red">*</span> : ""}
          </div>
          <p className="font-11">{field.subheading}</p>
        </div>
        
        <input 
          className="form-control placeholder-text" 
          type={field.kind} 
          onChange={(e) => {!taskForm ? handleFieldChange(field.id, e.target.value) : handleValueChange(field.id, e.target.value)}} 
          placeholder={taskForm ? field.placeholder : (field.placeholder != null ? field.placeholder : "")}
          maxLength={field.maxCharacters}
          required={field.isRequired}
        />
      </div>
    </React.Fragment>
  );
};

export default TextInput;
