import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import _ from 'lodash'; // setClients(prevState => _.reject(prevState, { token: client.token }));
// import {OrganizationContext} from "../../Shared/OrganizationContext.js";
// import {UserContext} from "../../Shared/UserContext.js";

const Form = ({organization_id, project_id, refetchData, closeModal, document}) => {
  // const {organizationAuthorizations} = useContext(OrganizationContext);
  // const currentUser = useContext(UserContext);
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [disabled, setDisabled] = useState(false);
  const [privateDocument, setPrivateDocument] = useState(document ? document.private : false);
  const [name, setName] = useState(document ? document.title : "");

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.post(`/api/o/${organization_id}/projects/${project_id}/documents`, {
      title: name,
      private: privateDocument
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        closeModal();
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const handleUpdate = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.put(`/api/o/${organization_id}/projects/${project_id}/documents/${document.token}`, {
      title: name,
      private: privateDocument
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        closeModal();
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  }


  return(
    <React.Fragment>
      <div className="row">
        <h4 className="modal-title">Create document</h4>

        <form onSubmit={document ? handleUpdate : handleSubmit}>
          <div className="field mb-5">
            <label>Name</label>
            <input type="text" className="form-control" value={name} onChange={(evt) => setName(evt.target.value)} required="true"/>
          </div>

          <div className="field mt-0">
            <p className="font-12 opacity-7">Documents are visible to all members of this project.</p>
          </div>

          <div className="field text-right">
            <button disabled={disabled} type="submit" className="btn btn-primary">{t("Submit")}</button>
          </div>
        </form>
      </div>
    </React.Fragment>
  )
}

export default Form;