import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import Toggle from 'react-toggle';
import {TaskboardContext} from "../Shared/TaskboardContext.js";

const NewTaskTemplate = ({task, refetchData, project_id, closeModal}) => {
  const {fetchTaskTemplates} = useContext(TaskboardContext);
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [recurring, setRecurring] = useState(false);
  const [recurrenceType, setRecurrenceType] = useState('');
  const [recurrenceDays, setRecurrenceDays] = useState([]);
  const [recurrenceDayOfMonth, setRecurrenceDayOfMonth] = useState(1);
  const [recurrenceMonth, setRecurrenceMonth] = useState(1);
  const [recurrenceDay, setRecurrenceDay] = useState(1);

  useEffect(() => {
    setRecurrenceDays([])
    setRecurrenceDayOfMonth(1)
    setRecurrenceMonth(1)
    setRecurrenceDay(1)
  },[recurrenceType])

  const createTaskTemplate = (e) => {
    e.preventDefault();
    setDisabled(true);

    axios.post(`/api/portal/projects/${project_id || match.params.project_id}/tasks/${task.token}/task_templates`,{
      title: title,
      task_data: task,
      subtasks_data: task.subtasks,
      recurrence_type: recurrenceType,
      recurrence_days: recurrenceDays,
      recurrence_day_of_month: recurrenceDayOfMonth,
      recurrence_month: recurrenceMonth,
      recurrence_day: recurrenceDay,
      recurring: recurring,
      column_id: task.column.token
    })
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        notice("Task template created");
        fetchTaskTemplates();
        closeModal();
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const handleRecurrenceDayChange = (e) => {
    const selectedDay = e.target.value;
    const isChecked = e.target.checked;
  
    if (isChecked) {
      // Add the selected day to the recurrenceDays array
      setRecurrenceDays([...recurrenceDays, selectedDay]);
    } else {
      // Remove the selected day from the recurrenceDays array
      setRecurrenceDays(recurrenceDays.filter(day => day !== selectedDay));
    }
  };
  

  return(
    <React.Fragment>
      <h4 className="color-black-always modal-title">Task templates</h4>
      <div className="row no-margin">
        <div className="alert-success mb-25">
          We'll save all data for this task and any subtasks for you to reuse later. Well, except for comments.
        </div>

        <form onSubmit={createTaskTemplate}>
          <div className="field">
            <label>Title</label>
            <input className="form-control" placeholder="Title to identify it" onChange={(e) => setTitle(e.target.value)} required="true"/>
          </div>

          <div className="field display-flex align-items-center">
            <Toggle checked={recurring} onChange={() => {setRecurring(!recurring);}} icons={false}/>
            <span className="ml-8">Recurring</span>
          </div>

          {recurring && (
            <div>
              <div className="field">
                <label>Repeat</label>
                <select value={recurrenceType} onChange={(e) => setRecurrenceType(e.target.value)}>
                  <option value="daily">Daily</option>
                  <option value="weekly">Weekly</option>
                  <option value="monthly">Monthly</option>
                  <option value="yearly">Yearly</option>
                </select>
              </div>

              {/* Render additional fields based on recurrence type */}
              {recurrenceType === 'weekly' && (
                <div className="field">
                  <label>On these days</label>
                  <div className="task-template-recurring-checklist">
                    <label className="task-template-recurring-label">
                      <input type="checkbox" className="form-control" value="sunday" checked={recurrenceDays.includes('sunday')} onChange={(e) => handleRecurrenceDayChange(e)} />
                      S
                    </label>
                    <label className="task-template-recurring-label">
                      <input type="checkbox" className="form-control" value="monday" checked={recurrenceDays.includes('monday')} onChange={(e) => handleRecurrenceDayChange(e)} />
                      M
                    </label>
                    <label className="task-template-recurring-label">
                      <input type="checkbox" className="form-control" value="tuesday" checked={recurrenceDays.includes('tuesday')} onChange={(e) => handleRecurrenceDayChange(e)} />
                      T
                    </label>
                    <label className="task-template-recurring-label">
                      <input type="checkbox" className="form-control" value="wednesday" checked={recurrenceDays.includes('wednesday')} onChange={(e) => handleRecurrenceDayChange(e)} />
                      W
                    </label>
                    <label className="task-template-recurring-label">
                      <input type="checkbox" className="form-control" value="thursday" checked={recurrenceDays.includes('thursday')} onChange={(e) => handleRecurrenceDayChange(e)} />
                      T
                    </label>
                    <label className="task-template-recurring-label">
                      <input type="checkbox" className="form-control" value="friday" checked={recurrenceDays.includes('friday')} onChange={(e) => handleRecurrenceDayChange(e)} />
                      F
                    </label>
                    <label className="task-template-recurring-label">
                      <input type="checkbox" className="form-control" value="saturday" checked={recurrenceDays.includes('saturday')} onChange={(e) => handleRecurrenceDayChange(e)} />
                      S
                    </label>
                  </div>
                </div>
              )}


              {recurrenceType === 'monthly' && (
                <div className="field">
                  <label>Day of Month</label>
                  <input min="1" max="28" type="number" value={recurrenceDayOfMonth} onChange={(e) => setRecurrenceDayOfMonth(parseInt(e.target.value))} className="form-control"/>
                </div>
              )}

              {recurrenceType === 'yearly' && (
                <div className="field row">
                  <div className="col-6 pr-15">
                    <label>Month</label>
                    <input className="form-control" min="1" max="12" type="number" value={recurrenceMonth} onChange={(e) => setRecurrenceMonth(parseInt(e.target.value))} />
                  </div>
                  <div className="col-6">
                    <label>Day</label>
                    <input className="form-control" min="1" max="31" type="number" value={recurrenceDay} onChange={(e) => setRecurrenceDay(parseInt(e.target.value))} />
                  </div>
                </div>
              )}
              {recurring && 
                <p className="opacity-6 font-12">
                  The task will be created on <span className="badge badge-blue mr-5 ml-5">{task.column.title}</span> in this project.
                </p>
              }
            </div>
          )}

          <div className="field text-right">
            <button className="btn btn-primary" type="submit"><i className="far fa-save mr-8"></i>Create</button>
          </div>
        </form>
      </div>
    </React.Fragment>
  )
}

export default NewTaskTemplate;