import React, {useEffect, useState} from 'react';
import { PickerOverlay } from 'filestack-react';
import { Modal } from 'react-responsive-modal';

const FilestackUploader = ({ image, setImage, classNames, fileType }) => {
  const [open, setOpen] = useState(false);

  const createReplay = (filestack) => {
    const filesUploaded = filestack.filesUploaded;
    const imageFiles = Object.values(filesUploaded)
    if (imageFiles.length > 0) {
      const imageFile = imageFiles[0];
      console.log(imageFile);
      // setImage(imageFile.url);
      setImage(imageFile.url)
    } else {
      console.log('No image file uploaded');
    }
  }

  return (
    <React.Fragment>
      <a onClick={() => setOpen(true)} className={classNames}>
        <i className="fal fa-upload mr-8"></i> Upload
      </a>

      {open && 
        <PickerOverlay
          apikey={"AKVhxMbEQhkOIryqBvSEQz"}
          onSuccess={(res) => console.log(res)}
          onUploadDone={(res) => {
            createReplay(res);
          }}
          clientOptions={{
            sessionCache: true
          }}
          pickerOptions={{
            onClose: () => {
              setOpen(false);
            }, 
            transformations: {
              crop: {
                aspectRatio: 16/9,
                force: true
              }
            },
            fromSources: ["local_file_system", "googledrive", "dropbox", "box", "onedrive", "onedriveforbusiness", "googlephotos", "facebook", "instagram"],
            maxFiles: 1,
            accept: 'image/*',
            storeTo: {
              location: 'gcs',
              path: '/',
              container: 'queue-filestack'
            }
          }}
        />
      }
    </React.Fragment>
  );
}

export default FilestackUploader;