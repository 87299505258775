import React , { useState, useEffect } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 

const LinkForm = ({project_id, closeModal, task_id, refetchData}) => {
  const match = useRouteMatch();
  const [disabled, setDisabled] = useState(false);
  const [title, setTitle] = useState(""); 
  const [link, setLink] = useState("");

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    setDisabled(true);
  
    axios.post(`/replays`, {
      project_id: match.params.project_id,
      task_id: task_id,
      replay: {
        title: title,
        link: link
      }
    })
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        refetchData();
        closeModal();
        setDisabled(false);
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  };
  


  return(
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="field">
            <label className="color-black-always">Title</label>
            <input className="form-control" placeholder="" type="text" required="true" onChange={(e) => setTitle(e.target.value)}/>
          </div>
          
          <div className="field">
            <label className="color-black-always">Link</label>
            <input className="form-control" placeholder="http://" type="url" required="true" onChange={(e) => setLink(e.target.value)}/>
          </div>

          <div className="col-12 field text-right">
            <button className="btn btn-primary" type="submit">{disabled ? "Submitting..." : "Submit"}</button>
          </div>
        </div>
      </form>
    </React.Fragment>
  )
}

export default LinkForm