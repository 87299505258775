import React from 'react';
import Filestack from "../Portal/Projects/TaskForms/Filestack.js";
import Linkify from 'react-linkify';

const UploadField = ({ field, removeField, handleLabelChange, taskForm, handleValueChange }) => {
  return (
    <React.Fragment>
      <div className="field task-form-left-side">
        <div className="mb-10">
          <div className="display-flex gap-8">
            <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
              <a href={decoratedHref} key={key} target="_blank">{decoratedText}</a>
            )}>
              {field.label}
            </Linkify>
            {field.isRequired ? <span className="color-red">*</span> : ""}
          </div>
          <p className="font-11">{field.subheading}</p>
        </div>

        <div className="field">
          <Filestack field={field} handleValueChange={handleValueChange}/>
          <p className="font-12 opacity-4 mt-15">
            {field.value.length} uploaded
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UploadField;
