import React from 'react';
import DatePicker from "react-datepicker";

const Deadline = ({date, setDate, closeModal}) => {
  return(
    <React.Fragment>
      <div className="display-flex align-items-center justify-content-center" style={{height: 300}}>
        <DatePicker
          inline
          selected={date}
          onChange={(date) => {setDate(date); closeModal()}}
          placeholderText="Set Due Date"
          popperPlacement="top-end"
        />
      </div>
    </React.Fragment>
  )
}

export default Deadline;