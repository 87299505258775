import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import FormFields from "./FormFields.js";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import FilestackUploader from "../../Shared/FilestackUploader.js";
import AIImage from "./AIImage.js";
import CategoryForm from "./Category/CategoryForm.js";
import { ReactSortable } from "react-sortablejs";
import { TaskFormContext } from "./TaskFormContext.js";
import { confirmAlert } from 'react-confirm-alert'; 
import Toggle from 'react-toggle';

const TaskForms = ({refetchData, taskForm, chosenServiceForm, setQuestions, closeModal, serviceForm, categories, setCategories, setQuestionsSections}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [selectedFieldType, setSelectedFieldType] = useState('');
  const [selectedService, setSelectedService] = useState(serviceForm != null ? (serviceForm.service != null ? serviceForm.service.token : null) : null);
  const [title, setTitle] = useState(serviceForm != null ? serviceForm.title : "");
  const [fields, setFields] = useState(chosenServiceForm != null ? chosenServiceForm.questions : (serviceForm != null ? serviceForm.questions : []));
  const availableFieldTypes = ['text', 'upload', 'checkbox', 'textarea', 'radio', "line", "header"];
  const [services, setServices] = useState([]);
  const [organizationUsers, setOrganizationUsers] = useState([]);
  const [projectUsers, setProjectUsers] = useState([]);
  const [serviceFormUsers, setServiceFormUsers] = useState(serviceForm ? serviceForm.service_form_users : []);
  const [coverUrl, setCoverUrl] = useState(serviceForm?.cover_url || "https://queue.nyc3.cdn.digitaloceanspaces.com/placeholder-image.svg");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [advancedSettings, setAdvancedSettings] = useState(false);
  const [disableDescription, setDisableDescription] = useState(serviceForm?.disable_description || false);
  const [disablePriority, setDisablePriority] = useState(serviceForm?.disable_priority || false);

  const [sections, setSections] = useState(
    chosenServiceForm?.sections || serviceForm?.sections || []
  );
  
  useEffect(() => {
    if(setQuestions != null ){
      setQuestions(fields);
    }
  },[fields]);

  useEffect(() => {
    if(setQuestionsSections != null ){
      setQuestionsSections(sections);
    }
  },[sections]);

  useEffect(() => {
    fetchServices();
    fetchUsers();
  },[])

  const fetchServices = () => {
    axios.get(`/api/o/${match.params.organization_id}/services.json`)
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        setServices(response.data.services); 
        setLoaded(true)
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      
    });
  }
  
  const fetchUsers = () => {
    axios.get(`/api/o/${match.params.organization_id}/people.json`)
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        setOrganizationUsers(response.data.organization_users);
        setProjectUsers(response.data.project_users);
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      
    });
  }

  const handleFieldTypeChange = (fieldType, kind, sectionId = null) => {
    if (availableFieldTypes.includes(fieldType)) {
      const defaultOptions = (fieldType === 'checkbox' || fieldType === 'radio') 
        ? ['Option 1', 'Option 2', 'Option 3']  // Default options for checkbox/radio
        : [];

      const newField = {
        id: Date.now(),
        type: fieldType,
        label: fieldType === 'header' ? 'Section Title' : fieldType,
        subheading: fieldType === 'header' ? '' : null,
        value: fieldType === 'checkbox' ? [] : '',
        options: defaultOptions,  // Use default options
        placeholder: null, 
        position: sectionId ? 
          (sections.find(s => s.id === sectionId)?.fields?.length || 0) + 1 : 
          (fields.length + 1), 
        kind: kind, 
        isRequired: false, 
        maxCharacters: null,
        isHeader: fieldType === 'header',
        section_id: sectionId
      };

      if (sectionId) {
        setSections(prevSections => prevSections.map(section => {
          if (section.id === sectionId) {
            return {
              ...section,
              fields: [...(section.fields || []), newField]
            };
          }
          return section;
        }));
      } else {
        setFields(prevFields => [...prevFields, newField]);
      }
      setSelectedFieldType(fieldType);
    }
  };

  // update the field object
  const updateField = (fieldId, updatedProps) => {
    // First check if the field is in the main fields array
    const fieldInMain = fields.some(field => field.id === fieldId);
    if (fieldInMain) {
      setFields(prevFields => prevFields.map(field => {
        if (field.id === fieldId) {
          return { ...field, ...updatedProps };
        }
        return field;
      }));
    } else {
      // If not in main fields, check sections
      setSections(prevSections => prevSections.map(section => {
        if (section.fields?.some(field => field.id === fieldId)) {
          return {
            ...section,
            fields: section.fields.map(field => {
              if (field.id === fieldId) {
                return { ...field, ...updatedProps };
              }
              return field;
            })
          };
        }
        return section;
      }));
    }
  };

  const updateFieldById = (id, key, newValue, sectionId = null) => {
    if(sectionId) {
      setSections(prevSections =>
        prevSections.map(section =>
          section.id === sectionId ? { ...section, fields: section.fields.map(field => field.id === id ? { ...field, [key]: newValue } : field) } : section
        )
      );
    } else {
      setFields(prevFields =>
        prevFields.map(field =>
          field.id === id ? { ...field, [key]: newValue } : field
        )
      );
    }
  };
  
  const handleFieldChange = (fieldId, placeholder) => {
    updateField(fieldId, { placeholder });
  };

  const handleLabelChange = (fieldId, label) => {
    updateField(fieldId, { label });
  };

  const handleOptionsChange = (fieldId, options) => {
    updateField(fieldId, { options });
  };

  const handleValueChange = (fieldId, value) => {
    updateField(fieldId, { value });
  };

  const handleRequiredChange = (fieldId, isRequired) => {
    notice("handleRequiredChange")
    updateField(fieldId, {isRequired})
  }

  const removeField = (fieldId, sectionId = null) => {
    confirmAlert({
      title: 'Are you sure?',
      message: 'This will permanently delete it. So please double check before you delete it.',
      buttons: [
        {
          label: 'Cancel',
          className: 'btn react-confirm-cancel-btn',
          onClick: () => {} // Do nothing if "No" is clicked
        },
        {
          label: 'Delete',
          className: 'btn btn-danger',
          onClick: () => {
            axios.delete(`/api/o/${match.params.organization_id}/service_forms/${serviceForm.token}/questions/${fieldId}?section_id=${sectionId}`)
            .then(function(response) {
              // console.log(response);
              if (response.data.success) {
                // refetchData();
                if (sectionId) {
                  notice(`remove ${fieldId} from section ${sectionId}`)
                  setSections(prevSections => prevSections.map(section => {
                    if (section.id === sectionId) {
                      return {
                        ...section,
                        fields: section.fields.filter(field => field.id !== fieldId)
                      };
                    }
                    return section;
                  }));
                } else {
                  setFields((prevFields) => prevFields.filter((field) => field.id !== fieldId));
                }
              }
            });
          }
        },
      ]
    });
  };

  const addOptionToField = (id, option, sectionId = null) => {
    if (sectionId) {
      // If sectionId is provided, update fields within that section
      setSections(prevSections => prevSections.map(section => {
        if (section.id === sectionId) {
          return {
            ...section,
            fields: section.fields.map(field => {
              if (field.id === id) {
                return {
                  ...field,
                  options: [...field.options, option]
                };
              }
              return field;
            })
          };
        }
        return section;
      }));
    } else {
      // Original logic for main fields
      setFields(prevFields => prevFields.map(field => {
        if (field.id === id) {
          return {
            ...field,
            options: [...field.options, option]
          };
        }
        return field;
      }));
    }
  };

  const reorderFields = (startIndex, endIndex) => {
    notice("fields reordered")
    // setFields((prevFields) => {
    //   const result = Array.from(prevFields);
    //   const [removed] = result.splice(startIndex, 1);
    //   result.splice(endIndex, 0, removed);
  
    //   // Update position values
    //   result.forEach((field, index) => {
    //     field.position = index + 1;
    //   });
  
    //   return result;
    // });
  };


  const handleRadioCheckboxOptionChange = (fieldId, optionIndex, newValue, sectionId = null) => {
    if (sectionId) {
      setSections((prevSections) =>
        prevSections.map((section) => ({
          ...section,
          fields: section.fields.map((field) =>
            field.id === fieldId
              ? {
                  ...field,
                  options: field.options.map((option, i) =>
                    i === optionIndex ? newValue : option
                  ),
                }
              : field
          ),
        }))
      );
    } else {
      setFields((prevFields) =>
        prevFields.map((field) =>
          field.id === fieldId
            ? {
                ...field,
                options: field.options.map((option, i) =>
                  i === optionIndex ? newValue : option
                ),
              }
            : field
        )
      );
    }
  };

  const handleRadioChange = (fieldId, value, sectionId = null) => {
    if (sectionId) {
      // Handle fields within sections
      setSections(prevSections => prevSections.map(section => {
        if (section.id === sectionId) {
          return {
            ...section,
            fields: section.fields.map(field => {
              if (field.id === fieldId) {
                return { ...field, value: value };
              }
              return field;
            })
          };
        }
        return section;
      }));
    } else {
      // Handle main fields
      setFields(prevFields => prevFields.map(field => {
        if (field.id === fieldId) {
          return { ...field, value: value };
        }
        return field;
      }));
    }
  };

  const removeOption = (fieldId, valueToRemove, sectionId = null) => {
    if (sectionId) {
      setSections(prevSections => prevSections.map(section => {
        if (section.id === sectionId) {
          return {
            ...section,
            fields: section.fields.map(field => {
              if (field.id === fieldId) {
                const filteredOptions = field.options.filter(option => {
                  console.log("Comparing:", { option, valueToRemove }); // Debug log
                  return option !== valueToRemove;
                });
                console.log("Filtered options:", filteredOptions); // Debug log
                return {
                  ...field,
                  options: filteredOptions
                };
              }
              return field;
            })
          };
        }
        return section;
      }));
    } else {
      setFields(prevFields => {
        const updatedFields = prevFields.map(field => {
          if (field.id === fieldId) {
            const filteredOptions = field.options.filter(option => {
              console.log("Comparing:", { option, valueToRemove }); // Debug log
              return option !== valueToRemove;
            });
            console.log("Filtered options:", filteredOptions); // Debug log
            return {
              ...field,
              options: filteredOptions
            };
          }
          return field;
        });
        return updatedFields;
      });
    }
  };
  
  const handleValueAdd = (fieldId, value, sectionId = null) => {
    if (sectionId) {
      // Handle fields within sections
      setSections(prevSections => prevSections.map(section => {
        if (section.id === sectionId) {
          return {
            ...section,
            fields: section.fields.map(field => {
              if (field.id === fieldId) {
                const updatedValue = field.value.includes(value)
                  ? field.value.filter((val) => val !== value)
                  : [...field.value, value];
                return { ...field, value: updatedValue };
              }
              return field;
            })
          };
        }
        return section;
      }));
    } else {
      // Handle main fields
      setFields(prevFields => prevFields.map(field => {
        if (field.id === fieldId) {
          const updatedValue = field.value.includes(value)
            ? field.value.filter((val) => val !== value)
            : [...field.value, value];
          return { ...field, value: updatedValue };
        }
        return field;
      }));
    }
  };

  const handleOptionChange = (e) => {
    if(e.target.value === ""){
      setSelectedService(null);
    } else {
      setSelectedService(e.target.value);
    }
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.post(`/api/o/${match.params.organization_id}/services/${selectedService}/service_forms`, {
      title: title,
      fields: fields,
      service_form_users: serviceFormUsers, 
      cover_url: coverUrl,
      service_form_categories: selectedCategories, 
      sections: sections,
      disable_description: disableDescription,
      disable_priority: disablePriority
    })
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        refetchData();
        closeModal();
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const handleUpdate = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.put
    (`/api/o/${match.params.organization_id}/services/${selectedService}/service_forms/${serviceForm.token}`, {
      fields: fields, 
      service_form_users: serviceFormUsers,
      service_form_id: serviceForm.token,
      selected_service_id: selectedService, 
      sections: sections,
      service_form: {
        cover_url: coverUrl, 
        title: title, 
        disable_description: disableDescription,
        disable_priority: disablePriority
      }
    })
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        refetchData();
        closeModal();
        // notice("updated successfully")
      }
    })
    .catch(function(error){
      console.log("error:", error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const toggleServiceFormUser = (user) => {
    if (serviceFormUsers.some(u => u.user_id === user.user_id)) {
      // If user is already in the array, remove them
      setServiceFormUsers(serviceFormUsers.filter(u => u.user_id !== user.user_id));
    } else {
      // If user is not in the array, add them
      setServiceFormUsers([...serviceFormUsers, user]);
    }
  }

  const services_list = services.map(service => {
    return(
      <option value={service.token}>{service.title}</option>
    )
  });

  const uniqueOrganizationUsers = organizationUsers.filter((user, index, self) =>
    index === self.findIndex(u => u.user_id === user.user_id)
  );

  const organization_users_list = uniqueOrganizationUsers.map(organization_user => {
    return (
      <li key={organization_user.user_id} onClick={() => toggleServiceFormUser(organization_user)}>
        <a className="color-1">
          <img src={organization_user.avatar} className="avatar-small mr-8" alt="avatar" />{organization_user.username}

          {serviceFormUsers.some(user => user.user_id === organization_user.user_id) && 
            <i class="far fa-check ml-15 color-blue"></i>
          }
        </a>
      </li>
    );
  });

  const uniqueProjectUsers = projectUsers.filter((user, index, self) =>
    index === self.findIndex(u => u.user_id === user.user_id)
  );

  const project_users_list = uniqueProjectUsers.map(project_user => {
    return (
      <li key={project_user.user_id} onClick={() => toggleServiceFormUser(project_user)}>
        <a className="color-1">
          <img src={project_user.avatar} className="avatar-small mr-8" alt="avatar" />{project_user.username}
          
          {serviceFormUsers.some(user => user.user_id === project_user.user_id) && 
            <i class="far fa-check ml-15 color-blue"></i>
          }
        </a>
      </li>
    );
  });

  const service_form_users_list = serviceFormUsers.map(service_form_user => {
    return(
     <li>
        <img src={service_form_user.avatar} className="avatar mr-8" />
     </li> 
    )
  });

  const createSection = () => {
    const newSection = {
      id: Date.now(),
      token: `temp-${Date.now()}`,
      title: "New Section",
      description: "Describe this section",
      fields: []
    };

    if (serviceForm) {
      axios.post(`/api/o/${match.params.organization_id}/service_forms/${serviceForm.token}/sections`, {
        title: newSection.title,
        description: newSection.description
      })
      .then(function(response){
        if(response.data.success){
          setSections(prevSections => [...prevSections, response.data.section]);
          refetchData();
          // Scroll to the new section
          setTimeout(() => {
            const newSectionElement = document.querySelector(`[data-section-token="${response.data.section.token}"]`);
            if (newSectionElement) {
              newSectionElement.scrollIntoView({ behavior: 'smooth' });
            }
          }, 100);
        }
      })
      .catch(function(error){
        console.log(error);
        notice("An error occurred");
      });
    } else {
      setSections(prevSections => [...prevSections, newSection]);
      // Scroll to the new section
      setTimeout(() => {
        const newSectionElement = document.querySelector(`[data-section-token="${newSection.token}"]`);
        if (newSectionElement) {
          newSectionElement.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100);
    }
  }; 

  const updateSection = (sectionToken, key, value) => {
    setSections(prevSections =>
      prevSections.map(section =>
        section.token === sectionToken ? { ...section, [key]: value } : section
      )
    );
  };

  const sections_list = sections.map(section => {

    const handleRemoveSection = () => {
      confirmAlert({
        title: 'Are you sure?',
        message: 'This will permanently delete the section and all fields within it.',
        buttons: [
          {
            label: 'Cancel',
            className: 'btn react-confirm-cancel-btn',
            onClick: () => {} // Do nothing if "No" is clicked
          },
          {
            label: 'Delete',
            className: 'btn btn-danger',
            onClick: () => {
              axios.delete(`/api/o/${match.params.organization_id}/service_forms/${serviceForm.token}/sections/${section.token}`)
              .then(function(response){
                if(response.data.success){
                  setSections(prevSections => prevSections.filter(s => s.token !== section.token));
                }
              })
            }
          },
        ]
      });
    };

    return(
      <div key={section.token} data-section-token={section.token} className="field position-relative section-items-wrapper">
        {!taskForm && 
          <i class="fas fa-grip-vertical section-grip-vertical"></i>
        }
        <div className="form-section">
          <div className="form-header-container">
            <div className="display-flex flex-direction-column header-title-wrapper ml-5">
              {taskForm ? (
                <>
                  <div className="form-header-input">
                    {section.title}
                  </div>
                  <div className="form-subheading-input">
                    {section.description}
                  </div>
                </>
              ):(
                <>
                  <input
                    type="text"
                    value={section.title}
                    onChange={(e) => updateSection(section.token, "title", e.target.value)}
                    className="form-header-input"
                    placeholder="Section Title"
                  />

                  <input
                    type="text"
                    value={section.description || ''}
                    onChange={(e) => updateSection(section.token, "description", e.target.value)}
                    className="form-subheading-input"
                    placeholder="Add a description"
                  />
                </>
              )}

              {!taskForm && 
                <a onClick={handleRemoveSection} style={{position: "absolute", right: "25px"}} className="btn btn-small background-hover color-1 border-all section-remove-button"><i className="far fa-trash-alt mr-5"></i>Remove</a>
              }
            </div>
          </div>
          
          <FormFields 
            fields={section.fields || []} 
            setFields={(updatedFields) => {
              setSections(prevSections => {
                const newSections = prevSections.map(s => {
                  if (s.id === section.id) {
                    return { ...s, fields: updatedFields };
                  }
                  return s;
                });
                console.log('After update - New sections state:', newSections);
                return newSections;
              });
            }}
            handleFieldChange={handleFieldChange}
            handleLabelChange={handleLabelChange}
            handleOptionsChange={handleOptionsChange}
            removeField={removeField}
            reorderFields={reorderFields}
            addOptionToField={addOptionToField}
            handleRadioChange={handleRadioChange}
            handleValueAdd={handleValueAdd}
            taskForm={taskForm}
            handleValueChange={handleValueChange}
            removeOption={removeOption}
            handleRadioCheckboxOptionChange={handleRadioCheckboxOptionChange}
            handleRequiredChange={handleRequiredChange}
            updateFieldById={updateFieldById}
            section={section}
          />
          {!taskForm && 
            <div className="section-actions">
              <div className="btn-group dropup">
                <button type="button" className="btn btn-primary-outline btn-small dropdown-toggle dark-color-black" data-toggle="dropdown">
                  <i className="far fa-plus mr-5"></i> Add field to section
                </button>
                <ul className="dropdown-menu animated fadeInUp" role="menu">
                  <li><a onClick={() => handleFieldTypeChange("text", "text", section.id)}><i class="far fa-horizontal-rule mr-5"></i> Text</a></li>
                  <li><a onClick={() => handleFieldTypeChange("textarea", "", section.id)}><i className="far fa-grip-lines mr-5"></i> Textarea</a></li>
                  <li><a onClick={() => handleFieldTypeChange("text", "link", section.id)}><i className="far fa-link mr-5"></i> Link</a></li>
                  <li><a onClick={() => handleFieldTypeChange("text", "date", section.id)}><i className="far fa-calendar mr-5"></i> Date</a></li>
                  <li><a onClick={() => handleFieldTypeChange("upload", "", section.id)}><i className="far fa-upload mr-5"></i> Upload</a></li>
                  <li><a onClick={() => handleFieldTypeChange("checkbox", "", section.id)}><i className="far fa-check-square mr-5"></i> Checkbox</a></li>
                  <li><a onClick={() => handleFieldTypeChange("radio", "", section.id)}><i className="far fa-dot-circle mr-5"></i> Radio</a></li>
                  {/* Add other field types as needed */}
                </ul>
              </div>
            </div>
          }
        </div>

      </div>
    );
  });

  return(
    <TaskFormContext.Provider value={{fields, setFields, handleFieldChange, handleLabelChange, handleOptionsChange, removeField, reorderFields, addOptionToField, handleRadioChange, handleValueAdd, taskForm, handleValueChange, removeOption, handleRadioCheckboxOptionChange, handleRequiredChange, updateFieldById}}>
      {!taskForm && 
        <div className="form-section">
          <div className="row">
            <div className="form-header-container">
              <div className="display-flex flex-direction-column header-title-wrapper ml-10">
                <div className="form-header-input">
                  Admin settings
                </div>
                <div className="form-subheading-input">
                  This won't be shown to your client, but it'll be used to modify how it works.
                </div>
              </div>
            </div>

            <div className="field task-form-cover">
              <div className="task-form-cover-image-container">
                <img src={coverUrl || "https://upload.wikimedia.org/wikipedia/commons/thumb/8/89/HD_transparent_picture.png/1200px-HD_transparent_picture.png"} className="task-form-cover-image" />
                <FilestackUploader image={coverUrl} setImage={setCoverUrl} classNames="btn btn-small background-hover color-1 border-all"/>
                <a onClick={() => setCoverUrl(null)} className="opacity-4 font-10 ml-8 btn btn-small background-hover color-1"><i className="far fa-trash-alt mr-8"></i>Remove image</a>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-4 pr-15">
                <div className="field row">
                  <label>Title</label>
                  <input required="true" className="form-control" value={title} onChange={(e) => setTitle(e.target.value)} required={true} placeholder="Name of the task"/>
                </div>
              </div>

              <div className="col-12 col-md-8">
                <div className="field">
                  <label>Service <span className="font-12 opacity-6">- Universal forms will be available for all clients.</span></label>
                  <select id="options-select" value={selectedService} onChange={handleOptionChange}>
                    <option value={""}>{loaded ? "Universal" : "Loading..."}</option>
                    {services_list}
                  </select>
                </div>
              </div>
            </div>
            
            <div className="field">
              <CategoryForm refetchData={refetchData} serviceForm={serviceForm} categories={categories} setCategories={setCategories} selectedCategories={selectedCategories} setSelectedCategories={setSelectedCategories} />
            </div>

            <div className="field">
              <div>
                <label>Assign members <span className="font-12 opacity-6">- Form will auto-assign to these people</span></label>
              </div>

              <div className="display-flex list-unstyled mt-5">
                {service_form_users_list}
                <div class="btn-group">
                  <a type="button" class="btn btn-small background-hover color-1 border-all dropdown-toggle" data-toggle="dropdown">
                    <i class="far fa-plus"></i>
                  </a>
                  <ul class="dropdown-menu animated fadeInUp" role="menu">
                    {organization_users_list}
                    <li class="divider"></li>
                    {project_users_list}
                  </ul>
                </div>
              </div>
            </div>

            <div className="">
              <a className="btn btn-small background-hover color-1 ml-15-negative opacity-7 opacity-10-hover" onClick={() => setAdvancedSettings(!advancedSettings)}><i className="far fa-cog mr-5"></i> Advanced settings</a>

              {advancedSettings && 
                <>
                  <div className="field display-flex align-items-center justify-content-between mt-15">
                    <div className="display-flex flex-direction-column">
                      <label className="mb-0">Description</label>
                      <p className="font-12 opacity-6">Disabling will hide the description field from being visible.</p>
                    </div>

                    <div>
                      <Toggle checked={!disableDescription} onChange={() => {setDisableDescription(!disableDescription)}} icons={false}/>
                    </div>
                  </div>

                  <div className="field display-flex align-items-center justify-content-between">
                    <div className="display-flex flex-direction-column">
                      <label className="mb-0">Priority</label>
                      <p className="font-12 opacity-6">Priority helps client tell you the urgency of the request.</p>
                    </div>

                    <div>
                      <Toggle checked={!disablePriority} onChange={() => {setDisablePriority(!disablePriority)}} icons={false}/>
                    </div>
                  </div>
                </>
              }
            </div>
          </div>
        </div>
      }

      <ReactSortable 
        list={sections} 
        setList={setSections}
        animation={200}
        ghostClass="sortable-ghost"
        dragClass="sortable-drag"
        chosenClass="sortable-chosen"
        handle=".section-grip-vertical">
        {sections_list}
      </ReactSortable>

      {(fields.length > 0) &&
        <div className="field position-relative">
          <div className="form-section">
            <div className="form-header-container">
              <div className="display-flex flex-direction-column header-title-wrapper ml-10">
                <div className="form-header-input">
                  Additional details
                </div>
                <div className="form-subheading-input">
                  These questions helps us understand your request better.
                </div>
              </div>
            </div>
            <FormFields fields={fields} setFields={setFields} handleFieldChange={handleFieldChange} handleLabelChange={handleLabelChange} handleOptionsChange={handleOptionsChange} removeField={removeField} reorderFields={reorderFields} addOptionToField={addOptionToField} handleRadioChange={handleRadioChange} handleValueAdd={handleValueAdd} taskForm={taskForm} handleValueChange={handleValueChange} removeOption={removeOption} handleRadioCheckboxOptionChange={handleRadioCheckboxOptionChange} handleRequiredChange={handleRequiredChange} updateFieldById={updateFieldById}/>
          </div>
        </div>
      }

      {!taskForm && 
        <div className="row">
          <div className="field">
            <div class="btn-group dropup">
              <button type="button" class="btn btn-primary-outline btn-small dropdown-toggle dark-color-black" data-toggle="dropdown">
              <i class="far fa-plus mr-5"></i> Add Fields
              </button>

              <ul class="dropdown-menu animated fadeInUp" role="menu">
                <li><a onClick={() => createSection()}><i class="far fa-heading"></i> Section</a></li>
                <li><a onClick={() => handleFieldTypeChange("text", "text")}><i class="far fa-horizontal-rule"></i> Text</a></li>
                <li><a onClick={() => handleFieldTypeChange("textarea", "")}><i class="far fa-grip-lines"></i> Textarea</a></li>
                <li><a onClick={() => handleFieldTypeChange("text", "link")}><i class="far fa-link"></i> Link</a></li>
                <li><a onClick={() => handleFieldTypeChange("text", "date")}><i class="far fa-calendar"></i> Date</a></li>
                <li><a onClick={() => handleFieldTypeChange("upload", "")}><i class="far fa-upload"></i> Upload</a></li>
                <li><a onClick={() => handleFieldTypeChange("checkbox", "")}><i class="far fa-check-square"></i> Checkbox</a></li>
                <li><a onClick={() => handleFieldTypeChange("radio", "")}><i class="far fa-dot-circle"></i> Radio</a></li>
              </ul>
            </div>
          </div>

          <div className="field text-right">
            <button disabled={disabled || !title } onClick={serviceForm != null ? handleUpdate : handleSubmit} className="btn btn-primary">
              {serviceForm != null ? "Update" : "Create"}
            </button>
          </div>
        </div>
      }
    </TaskFormContext.Provider>
  )
}

export default TaskForms