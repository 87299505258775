import React , { useState, useEffect } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import FullCardSubtask from "./FullCard.js";
import { useContextMenu } from "react-contexify";
import { useTranslation } from 'react-i18next';
import TaskDropdown from "./TaskForms/TaskDropdown.js";
import StageIcon from "../../../Shared/StageIcon.js";
import DatePicker from "react-datepicker";
import NestedSubtask from "./SubTask.js";
import Deadline from "./Task/Deadline.js";
import Priority1Icon from "../../../Shared/svg/priority1.jsx";
import Priority2Icon from "../../../Shared/svg/priority2.jsx";
import Priority3Icon from "../../../Shared/svg/priority3.jsx";

const SubTask = ({subtask, columns, refetchData, organizationUser, project_id, fetchTask, setSubtasks, openSubtaskToken, setOpenSubtaskToken}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [openSubtask, setOpenSubtask] = useState(false);
  const [openDeadline, setOpenDeadline] = useState(false);

  const { show } = useContextMenu({
    id: subtask.token
  });
  function displayMenu(e){
    e.stopPropagation();
    // put whatever custom logic you need
    // you can even decide to not display the Menu
    show({
      event: e,
    });
  }
  const { show: showContextMenu } = useContextMenu();
  const clientPortal = window.location.pathname.includes("/portal/o/")

  useEffect(() => {
    if (openSubtaskToken === subtask.token) {
      setOpenSubtask(true);
      setOpenSubtaskToken(null); // Reset the token after opening
    }
  }, [openSubtaskToken, subtask.token]);

  const moveTask = (column_id) => {
    axios.post(`/api/portal/projects/${project_id || match.params.project_id}/tasks/${subtask.token}/update_position`, {
      column_id: column_id,
      position: 0,
    })
    .then(function (response) {
      setSubtasks(prevSubtasks => prevSubtasks.map(st => 
        st.token === response.data.task_data.token ? response.data.task_data : st
      ));
      refetchData();
    })
    .catch(function (error) {
      console.log(error);
      // Handle the error as needed
    });
  }

  const columns_list = columns.filter(column => column.token !== subtask.column.token).map(column => (
    <li key={column.token}>
      <a className="stage-dropdown-a" onClick={() => moveTask(column.token)}>
        <StageIcon stage={column.stage}/>{column.title}
      </a>
    </li>
  ));


  const handleDeadlineChange = (date) => {
    axios.put(`/api/portal/projects/${project_id || match.params.project_id}/tasks/${subtask.token}`, {
      deadline: date
    })
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        setSubtasks(prevSubtasks => prevSubtasks.map(st => 
          st.token === response.data.task_data.token ? response.data.task_data : st
        ));
        refetchData();
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  };

  const markFinished = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDisabled(true);

    axios.post(`/api/portal/projects/${project_id || match.params.project_id}/tasks/${subtask.token}/mark_finished`, {
      finished: !subtask.finished
    })
    .then(function(response){
      if(response.data.success){
        setSubtasks(prevSubtasks => prevSubtasks.map(st => 
          st.token === response.data.task_data.token ? response.data.task_data : st
        ));
        refetchData();
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error);
      notice("An error occurred");
      reportError(`File: SubTask.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .finally(function () {
      setDisabled(false);
    });
  }

  const subtask_users = subtask.users.map(user => {
    return(
      <>
        <img data-tip={user.username} src={user.avatar} className="avatar-more ml-5" />
      </>
    )
  });

  const subtasks_list = subtask.subtasks.map(subtask => (
    <div key={subtask.token} className={`row row background-hover-light align-items-center display-flex`} style={{paddingTop: "2px", paddingBottom: "2px", paddingLeft: "25px"}}>
      <NestedSubtask 
        setSubtasks={setSubtasks}
        subtask={subtask} 
        columns={columns} 
        fetchTask={fetchTask}
        refetchData={() =>{fetchTask()}} 
        organizationUser={organizationUser} 
        project_id={project_id} 
        openSubtaskToken={openSubtaskToken}
        setOpenSubtaskToken={setOpenSubtaskToken}
      />
    </div>
  ));
  

  return(
    <React.Fragment>
      <div className="display-flex align-items-center col-11 justify-content-between">
        <div className="display-flex align-items-center">
          {/* Column dropdown */}
          <div class="btn-group">
            <button type="button" class="subtask-column-dropdown-button border-none dropdown-toggle background-hover display-flex align-items-center" data-toggle="dropdown" >
              <StageIcon size={15} stage={subtask.column.stage} classNames="display-flex align-items-center"/>
            </button>

            <ul class="dropdown-menu animated fadeInUp" role="menu">
              {columns_list}
            </ul>
          </div>

          {/* Task title */}
          <div data-html={true} data-tip={`<div class="tooltip-keybind-wrapper"><span class="tooltip-keybind">J or K</span></div>`} className="display-flex align-items-center  font-12 border-radius cursor-pointer ml-5" onClick={() => {setOpenSubtask(true);}} style={{padding: "5px 0px"}}>
            <div className="opacity-4 opacity-10-hover cursor-pointer truncate" style={{maxWidth: "200px"}}>
              {subtask.title} 
            </div>

            {subtask.private && 
              <i className="fas fa-lock ml-8 opacity-4"></i>
            }

            {(subtask?.subtasks?.length > 0 && subtask?.subtasks_finished != null ) &&
              <>
                <span data-tip="View subtasks"  onClick={() => setOpenSubtask(true)} className={`badge ${(subtask.subtasks.length / subtask.subtasks_finished.length) === 1 ? "badge-green" : ""} background-hover background-3 cursor-pointer font-weight-500 task-deadline-wrapper`}>
                  {((subtask.subtasks.length / subtask.subtasks_finished.length) === 1) ? (
                    <>
                      <i class="far fa-check-square mr-5"></i>
                    </>
                  ):(
                    <>
                      <i class="far fa-square mr-5"></i>
                    </>
                  )}
                  {subtask.subtasks_finished.length}/{subtask.subtasks.length}
                </span>
              </>
            }
          </div>
        </div>

        <div className="display-flex align-items-center text-right">
          {/* Priority */}
          {(subtask.priority != "" || subtask.priority != null) && 
            <div className="ml-8">
              <div children="priority-icon-wrapper">
                <div className="display-flex align-items-center">
                  {subtask.priority == "Low" && <Priority1Icon/>}
                  {subtask.priority == "Medium" && <Priority2Icon/>}
                  {subtask.priority == "High" && <Priority3Icon/>}
                </div>
              </div>
            </div>
          }

          {/* labels */}
          {subtask?.task_labels?.map(task_label => (
            <>
              <span class="badge border-all background-active" style={{marginRight: 0, marginLeft: 5}}><span class="badge-label-color" style={{background: `#${task_label.label.color}`}}></span>{task_label.label.title}</span>
            </>
          ))}

          {/* Deadline */}
          {subtask.deadline && 
            <>
              <div className="badge badge-blue cursor-pointer" style={{marginRight: 0, marginLeft: 5}} onClick={() => !clientPortal ? setOpenDeadline(true) : setOpenDeadline(false)}>
                <i class="far fa-calendar-day mr-5"></i> {subtask.deadline_short}
              </div>

              <Modal open={openDeadline} onClose={() => setOpenDeadline(false)} className="modal-body-white" classNames={{modal: 'width-300'}} center>
                <Deadline date={subtask.deadline} setDate={handleDeadlineChange} closeModal={() => setOpenDeadline(false)}/>
              </Modal>
            </>
          }

          {/* task_users */}
          {subtask.users.length > 1 ? (
            <div className="subtasks-list-users ml-8">
              <div data-tip={`Assigned to ${subtask.users.map(user => user.username).join(', ')}`} className="avatar-more border-all background-3 text-center" style={{zIndex: 1, width: 23, height: 23}}>+{subtask.users.length}</div>
              <img data-tip={subtask.users[0].username} src={subtask.users[0].avatar} className="avatar-more ml-15-negative"/>
            </div>
          ) : (
            <>
              {subtask_users}
            </>
          )}
        </div>
      </div>
      
      <div className="text-right col-1">
        {!clientPortal && 
          <TaskDropdown task={subtask} refetchData={() => {refetchData(); fetchTask()}} columns={columns} project_id={project_id} organizationUser={organizationUser} displayMenu={displayMenu} subtask={true} setArray={setSubtasks}/>
        }
      </div>

      <Modal focusTrapped={false} open={openSubtask} onClose={() => {
          setOpenSubtask(false);
          // Remove the subtask_id from the URL when closing the modal
          const searchParams = new URLSearchParams(location.search);
          searchParams.delete('subtask_id');
          history.replace({ search: searchParams.toString() });
        }}  className="modal-body-white" classNames={{modal: 'modal-side'}} center>
        <FullCardSubtask task_id={subtask.token} refetchData={() => {refetchData(); fetchTask()}} project_id={project_id}/>
      </Modal>

      
      {subtasks_list}
    </React.Fragment>
  )
}

export default SubTask;