import React from 'react';
import Linkify from 'react-linkify';

const LineGroup = ({ field, handleFieldChange, removeField, handleLabelChange, taskForm, handleValueChange }) => {
  return (
    <React.Fragment>
      <div className="field">
        <div className="task-form-line-field">
          {!taskForm && 
            <div>
              <i onClick={() => removeField()} className="fas fa-times color-red opacity-4 opacity-10-hover animate padding-8 cursor-pointer float-right"></i>
            </div>
          }
          <hr />
          {!taskForm ? (
            <div className={`task-form-line-label animate ${!taskForm ? "border-all-blue-hover" : ""}`} style={{cursor: "text"}} contentEditable={!taskForm} onBlur={(e) => handleLabelChange(field.id, e.target.textContent)}>
              {field.label}
            </div>
          ):(
            <div className="task-form-line-label">
              <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                <a href={decoratedHref} key={key} target="_blank">{decoratedText}</a>
              )}>
                {field.label}
              </Linkify>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default LineGroup;