import React , { useState, useEffect } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import EmbedForm from "./EmbedForm.js";
import { Modal } from 'react-responsive-modal';
import { confirmAlert } from 'react-confirm-alert'; 


const EmbedDropdown = ({embed, refetchData, project_id, className}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);

  const confirmDelete = () => {
    confirmAlert({
      title: 'Are you absolutely sure?',
      message: 'This action cannot be undone. This will permanently delete your embed and remove it from your project.',
      buttons: [
        {
          label: 'Cancel',
          className: 'btn react-confirm-cancel-btn',
          onClick: () => {} // Do nothing if "No" is clicked
        },
        {
          label: 'Delete',
          className: 'btn btn-danger',
          onClick: () => deleteEmbed()
        },
      ]
    });
  }

  const deleteEmbed = (evt) => {
    setDisabled(true);

    axios.delete(`/api/o/${match.params.organization_id}/projects/${project_id || match.params.project_id}/embeds/${embed.token}`)
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        if(location.href.includes(embed.token)){
          history.push(`/o/${match.params.organization_id}/projects/${project_id || match.params.project_id}`)
        }
        refetchData();
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }

  return(
    <React.Fragment>
      <div class={`btn-group ${embed.sidenav ? "hover-child-opacity position-absolute right-10" : "hover-child embed-edit-tabs-btn"} ml-5 ${className}`}>
        <a type="button" class={`dropdown-toggle pl-5 pr-5 opacity-4 opacity-10-hover color-1 ${embed.sidenav ? "embed-edit-btn background-hover-2" : ""}`} data-toggle="dropdown" style={{paddingLeft: "10px"}}>
          <i class="fas fa-ellipsis-v"></i>
        </a>

        <ul class="dropdown-menu animated fadeInUp left-dropdown min-width-unset" role="menu">
          <li><a onClick={() => setOpen(true)}><i class="fal fa-pencil"></i>Edit</a></li>
          <li><a onClick={confirmDelete}><i class="fal fa-trash-alt"></i>Delete</a></li>
        </ul>
      </div>

      <Modal open={open} onClose={() => setOpen(false)} className="modal-body-white" classNames={{modal: 'modal-full-width'}} center>
        <EmbedForm closeModal={() => setOpen(false)} refetchData={refetchData} embedData={embed}/>
      </Modal>
    </React.Fragment>
  )
}

export default EmbedDropdown;