import React, { useState, useEffect, useContext } from 'react';
import axios from "axios";
import _ from 'lodash'; // setClients(prevState => _.reject(prevState, { token: client.token }));
import { TaskboardContext } from '../Shared/TaskboardContext.js';
import { Modal } from 'react-responsive-modal';
import FullCard from '../Daas/Portal/Projects/FullCard.js';

const NoTaskDeadlines = ({ tasks, setEvents, start, end, closeModal }) => {
  const [disabled, setDisabled] = useState(false);
  const { columns, setColumns } = useContext(TaskboardContext);
  const [open, setOpen] = useState(false);
  const [taskId, setTaskId] = useState(null);
  const [projectId, setProjectId] = useState(null);

  useEffect(() => {
    $(document).ready(function () {
      const filterTasks = () => {
        const taskValue = $("#searchTasksForCalendar").val().toLowerCase();
        const projectValue = $("#calendarTaskProjectFilter").val().toLowerCase();
        const columnValue = $("#calendarTaskColumnFilter").val().toLowerCase(); // New column filter

        $("#deadlineTasks .task-item").filter(function () {
          const taskText = $(this).find('.calendar-tasks-title').text().toLowerCase();
          const projectText = $(this).find('.calendar-tasks-project-title').text().toLowerCase();
          const columnText = $(this).find('.calendar-tasks-column-title').text().toLowerCase(); // New column text

          const matchTask = taskText.indexOf(taskValue) > -1;
          const matchProject = projectText.indexOf(projectValue) > -1;
          const matchColumn = columnText.indexOf(columnValue) > -1; // New column match

          $(this).toggle(matchTask && matchProject && matchColumn);
        });
      };

      $("#searchTasksForCalendar, #calendarTaskProjectFilter, #calendarTaskColumnFilter").on("keyup", filterTasks); // Attach event listener to the new input field
    });
  }, []);

  const handleSubmit = (task) => {
    setDisabled(true);

    axios.put(`/api/portal/projects/${task.project_id}/tasks/${task.token}`, {
      start_date: start,
      deadline: end, 
      dashboard_task: true
    })
      .then(function (response) {
        if (response.data.success) {
          const updatedTask = {
            ...response.data.dashboard_task,
            start: new Date(response.data.dashboard_task.start),
            end: new Date(response.data.dashboard_task.end)
          };
          // Add the new task to the events array
          setEvents(prevEvents => [...prevEvents, updatedTask]);
          // Update the task in columns
          setColumns(prevColumns => {
            return prevColumns.map(column => {
              if (column.title === task.column.title) {
                return {
                  ...column,
                  tasks: column.tasks.map(t => 
                    t.token === task.token ? response.data.task_data : t
                  )
                };
              }
              return column;
            });
          });
          // refetchData();
          closeModal(); 
        } else {
          response.data.errors.forEach((error) => {
            notice(error);
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        notice("An error occurred");
        reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
      })
      .then(function () {
        setDisabled(false);
      });
  };

  const tasks_list = tasks.map(task => {
    return (
      <div key={task.token} className="row default-padding background-hover border-top cursor-pointer position-relative pl-0 task-item hover-parent-opacity" onClick={() => { handleSubmit(task); }}>
        <div disabled={disabled} className="col-6 truncate pr-15 calendar-tasks-title truncate">
          {task.title}
          <i className="fa fa-eye btn btn-small background-hover-2 view-task-btn ml-15 hover-child-opacity" onClick={(e) => { e.stopPropagation(); setTaskId(task.token); setProjectId(task.project.token); setOpen(true); }}/>
        </div>

        <div className="col-3 opacity-6 calendar-tasks-project-title truncate">
          {task.project.title}
        </div>

        <div className="col-3 opacity-6 calendar-tasks-column-title truncate">
          {task.column?.title}
        </div>
      </div>
    )
  });

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-4 pr-15">
          <input id="searchTasksForCalendar" className="form-control" placeholder="Search for task" />
        </div>

        <div className="col-4 pr-15">
          <input id="calendarTaskProjectFilter" className="form-control" placeholder="Search for a project" />
        </div>

        <div className="col-4 pr-15">
          <input id="calendarTaskColumnFilter" className="form-control" placeholder="Search for a column" /> {/* New input field */}
        </div>
      </div>

      <div id="deadlineTasks" className="pt-15">
        {tasks_list}
      </div>

      <Modal focusTrapped={false} closeOnEsc={false} open={open} onClose={() => {setOpen(false); setTaskId(null); setProjectId(null); }} className="modal-body-white" classNames={{modal: 'modal-side'}} center>
        <FullCard task_id={taskId} refetchData={() => {}} project_id={projectId}/>
      </Modal>
    </React.Fragment>
  )
}

export default NoTaskDeadlines;
