import React, {useState, useEffect} from 'react';
import TextInput from './TextInput';
import UploadField from './UploadField';
import CheckboxGroup from './CheckboxGroup';
import TextArea from './TextArea';
import RadioGroup from './RadioGroup';
import LineGroup from "./LineGroup.js";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Toggle from 'react-toggle';
import "./style.css"

const FormFields = ({
  fields,
  setFields,
  handleFieldChange,
  removeField,
  handleOptionsChange,
  handleLabelChange,
  handleRadioChange,
  handleValueAdd,
  taskForm,
  handleValueChange, 
  addOptionToField, 
  removeOption, 
  handleRadioCheckboxOptionChange,
  updateFieldById,
  section
}) => {
  const [selectedField, setSelectedField] = useState(null);
  const [hide, setHide] = useState(false);
  const [sideBoxPosition, setSideBoxPosition] = useState(0);

  useEffect(() => {
    if (selectedField) {
      const updatedField = fields.find(field => field.id === selectedField.id);
      setSelectedField(updatedField);
    }
  }, [fields]);

  useEffect(() => {
    if (selectedField) {
      const fieldElement = document.getElementById(selectedField.id);
      if (fieldElement) {
        const fieldPosition = fieldElement.offsetTop;
        setSideBoxPosition(fieldPosition);
      }
    }
  }, [selectedField]);
  
  const renderField = (field, index) => {
    const { id, type } = field;
  
    // Return a wrapper <div> with key and id attributes
    return (
      <div key={id} id={id} className={`${selectedField == field ? "task-form-edit-selected" : "task-form-edit"} ml-15-negative pl-15`} onClick={() => setSelectedField(field)}>
        {(() => {
          switch (type) {
            case 'text':
              return (
                <TextInput
                  field={field}
                  handleFieldChange={handleFieldChange}
                  handleLabelChange={handleLabelChange}
                  taskForm={taskForm}
                  handleValueChange={handleValueChange}
                  removeField={() => removeField(id, section?.id)}
                  section={section}
                />
              );
            case 'upload':
              return (
                <UploadField
                  field={field}
                  handleFieldChange={handleFieldChange}
                  handleLabelChange={handleLabelChange}
                  taskForm={taskForm}
                  handleValueChange={handleValueChange}
                  removeField={() => removeField(id, section?.id)}
                  section={section}
                />
              );
            case 'checkbox':
              return (
                <CheckboxGroup
                  field={field}
                  id={id}
                  label={field.label}
                  options={field.options}
                  selectedOptions={field.value}
                  onChange={(selectedOptions) =>
                    handleFieldChange(id, selectedOptions)
                  }
                  removeField={() => removeField(id, section?.id)}
                  handleOptionsChange={handleOptionsChange}
                  addOptionToField={addOptionToField}
                  taskForm={taskForm}
                  handleLabelChange={handleLabelChange}
                  handleRadioCheckboxOptionChange={handleRadioCheckboxOptionChange}
                  removeOption={removeOption}
                  handleValueAdd={handleValueAdd}
                  section={section}
                />
              );
            case 'textarea':
              return (
                <TextArea
                  field={field}
                  id={id}
                  label={field.label}
                  value={field.value}
                  onChange={(e) => handleFieldChange(id, e.target.value)}
                  taskForm={taskForm}
                  handleValueChange={handleValueChange}
                  removeField={() => removeField(id, section?.id)}
                  handleLabelChange={handleLabelChange}
                  handleFieldChange={handleFieldChange}
                  section={section}
                />
              );
            case 'radio':
              return (
                <RadioGroup
                  field={field}
                  id={id}
                  label={field.label}
                  options={field.options}
                  selectedOption={field.value}
                  onChange={(selectedOption) =>
                    handleFieldChange(id, selectedOption)
                  }
                  addOptionToField={addOptionToField}
                  handleRadioChange={handleRadioChange}
                  taskForm={taskForm}
                  removeField={() => removeField(id, section?.id)}
                  handleLabelChange={handleLabelChange}
                  removeOption={removeOption}
                  handleRadioCheckboxOptionChange={handleRadioCheckboxOptionChange}
                  section={section}
                />
              );
            case 'line':
              return (
                <LineGroup
                  field={field}
                  id={id}
                  label={field.label}
                  options={field.options}
                  selectedOption={field.value}
                  onChange={(selectedOption) =>
                    handleFieldChange(id, selectedOption)
                  }
                  addOptionToField={addOptionToField}
                  handleRadioChange={handleRadioChange}
                  taskForm={taskForm}
                  removeField={() => removeField(id, section?.id)}
                  handleLabelChange={handleLabelChange}
                  removeOption={removeOption}
                  handleRadioCheckboxOptionChange={handleRadioCheckboxOptionChange}
                  section={section}
                />
              );
            default:
              return null;
          }
        })()}
      </div>
    );
  };
  

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
  
    const updatedFields = [...fields];
    const [reorderedField] = updatedFields.splice(result.source.index, 1);
    updatedFields.splice(result.destination.index, 0, reorderedField);
  
    // Update the position property for all fields based on the new order
    updatedFields.forEach((field, index) => {
      field.position = index + 1; // Assuming positions are 1-based
    });
  
    setFields(updatedFields);
  };
  
  const allowSelectedFields = ["text", "textarea"]


  return (
    <>
      {taskForm ? (
        <React.Fragment>
          {fields.map((field, index) => renderField(field, index))}
        </React.Fragment>
      ):(
        <>
          {(!hide && selectedField) && (
            <div 
              className="modal-side-box default-padding background-3 animated fadeInUp"
              style={{ 
                position: 'absolute',
                top: `${sideBoxPosition}px`,
                right: '0'
              }}
            >
              <div className="display-flex align-items-center space-between field">
                <h4 className="margin-none mt-0">Field Settings</h4>
                <a 
                  className="btn btn-small color-1 task-form-edit-close mt-5 background-hover" 
                  onClick={() => setSelectedField(null)}
                >
                  <i className="fas fa-times"></i>
                </a>
              </div>

              <div className="field display-flex align-items-center space-between">
                <label className="mb-0">Required</label>
                <Toggle className="toggle-small" checked={selectedField.isRequired} onChange={() => {updateFieldById(selectedField.id, "isRequired", !selectedField.isRequired, section?.id)}} />
              </div>

              <div className="field">
                <label>Label</label>
                <input 
                  className="form-control form-control-small" 
                  placeholder="Enter helpful information here." 
                  value={selectedField.label || ''} 
                  onChange={(e) => {updateFieldById(selectedField.id, "label", e.target.value, section?.id)}} 
                />
              </div>

              <div className="field">
                <label>Subheading</label>
                <input 
                  className="form-control form-control-small" 
                  value={selectedField.subheading || ''} 
                  onChange={(e) => {updateFieldById(selectedField.id, "subheading", e.target.value, section?.id)}} 
                />
              </div>
              
              {(allowSelectedFields.includes(selectedField?.type) && selectedField?.kind != "date") && 
                <>
                  <div className="field">
                    <label>Placeholder</label>
                    <input 
                      className="form-control form-control-small" 
                      value={selectedField.placeholder || ''} 
                      onChange={(e) => {updateFieldById(selectedField.id, "placeholder", e.target.value, section?.id)}} 
                    />
                  </div>
                  
                  <div className="field">
                    <label>Max Characters {selectedField.maxCharacters}</label>
                    <input 
                      value={selectedField.maxCharacters || ''} 
                      className="form-control form-control-small" 
                      placeholder="0 - 9999999. Leave blank for no limit." 
                      type="number" 
                      onChange={(e) => {updateFieldById(selectedField.id, "maxCharacters", e.target.value, section?.id)}} 
                    />
                  </div>
                </>
              }
              <div className="text-center">
                <a className="btn btn-small color-red background-hover" onClick={() => {removeField(selectedField.id, section?.id)}}>Remove field</a>
              </div>
            </div>
          )}

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="sortable">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {fields.map((field, index) => (
                    <Draggable key={String(field.id)} draggableId={String(field.id)} index={index}>
                      {(provided) => (
                        <div ref={provided.innerRef} {...provided.draggableProps}>
                          <div className="task-form-move" {...provided.dragHandleProps} style={{width: "20px"}}>
                            <i class="fas fa-grip-vertical"></i>
                          </div>

                          {renderField(field, index)}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </>
      )}
    </>
  );
};

export default FormFields;
