import React , { useState, useContext } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import _ from 'lodash'; // setClients(prevState => _.reject(prevState, { token: client.token }));
import {Menu, Item, useContextMenu} from "react-contexify";
import DocumentForm from "./Form.js";
import { confirmAlert } from 'react-confirm-alert'; 
import {ProjectContext} from "../ProjectContext.js";

const DocumentItem = ({document, project, portal, refetchData}) => {
  const {projects, groups, setProjects, setGroups} = useContext(ProjectContext);
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDocumentEdit, setOpenDocumentEdit] = useState(false);

  const handleContextMenu = e => {
    e.preventDefault();
    e.stopPropagation();
    useContextMenu({ id: `project-${project.token}-document-${document.token}-dropdown`, position: { x: e.clientX - 200, y: e.clientY + 20 } }).show({ event: e });
  };

  const deleteDocument = () => {
    setDisabled(true);
    axios.delete(`/api/o/${match.params.organization_id}/projects/${project.token}/documents/${document.token}`)
    .then(function(response){
      if(response.data.success){
        // Update projects state by removing the deleted document
        const updatedProjects = projects.map(p => {
          if (p.token === project.token) {
            return {
              ...p,
              documents: p.documents?.filter(doc => doc.token !== document.token)
            };
          }
          return p;
        });

        // Update groups state by removing the deleted document
        const updatedGroups = groups.map(group => ({
          ...group,
          projects: group.projects.map(p => {
            if (p.token === project.token) {
              return {
                ...p,
                documents: p.documents?.filter(doc => doc.token !== document.token)
              };
            }
            return p;
          })
        }));

        // Update both states
        setProjects(updatedProjects);
        setGroups(updatedGroups);
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const confirmDelete = () => {
    confirmAlert({
      title: 'Are you sure?',
      message: 'This will permanently delete your document and remove it from your project.',
      buttons: [
        {
          label: 'Cancel',
          className: 'btn react-confirm-cancel-btn',
          onClick: () => {} // Do nothing if "No" is clicked
        },
        {
          label: 'Delete',
          className: 'btn btn-danger',
          onClick: () => deleteDocument()
        },
      ]
    });
  }

  return(
    <React.Fragment>
      <div className="display-flex align-items-center hover-parent-opacity">
        <li className="daas-platform-links-li animate sidebar-text-color side-nav-submenu-li width-100-percent">
          <NavLink activeClassName="background-active" to={`${portal}/o/${match.params.organization_id}/projects/${project.token}/documents/${document.token}`} className="daas-platform-links-a sidebar-text-color side-nav-submenu">
            {document.title}
          </NavLink>

          <div className="align-items-center hover-icons-container">
            {!location.pathname.includes("/portal/o/") && 
              <>
                <a onClick={handleContextMenu} className="navigation-project-item-btn background-hover color-1 opacity-4 opacity-10-hover sidebar-text-color">
                  <i className="fas fa-ellipsis-v"></i>
                </a>

                <Menu id={`project-${project.token}-document-${document.token}-dropdown`}>
                  <Item onClick={() => setOpenDocumentEdit(true)}>
                    <i className="fal fa-pencil"></i>Edit
                  </Item>

                  <Item onClick={confirmDelete} disabled={disabled}>
                    <i className="fal fa-trash"></i>Delete
                  </Item>
                </Menu>
              </>
            }
          </div>
        </li>
      </div>

      {openDocumentEdit && 
        <Modal open={openDocumentEdit} onClose={() => setOpenDocumentEdit(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
          <DocumentForm document={document} organization_id={match.params.organization_id} project_id={project.token} closeModal={() => {setOpenDocumentEdit(false); refetchData()}} refetchData={refetchData}/>
        </Modal>
      }
    </React.Fragment>
  )
}

export default DocumentItem;