import React, {useState, useRef, useContext} from 'react';
import Linkify from 'react-linkify';
import { TaskFormContext } from './TaskFormContext.js';

const CheckboxGroup = ({ field, selectedOptions, onChange, removeField,  taskForm, handleLabelChange, section }) => {
  const {addOptionToField, removeOption, handleRadioCheckboxOptionChange, handleValueAdd} = useContext(TaskFormContext);
  const [value, setValue] = useState('');
  const spanRef = useRef(null);
  
  const handleCheckboxChange = (option) => {
    const updatedOptions = selectedOptions.includes(option)
      ? selectedOptions.filter((selectedOption) => selectedOption !== option)
      : [...selectedOptions, option];
    onChange(updatedOptions);
  };


  const handleKeyPress = (e) => {
    setValue(spanRef.current.textContent);

    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent the default behavior of creating a new line
      
      // Perform the desired action here
      console.log('Enter key pressed');
      addOptionToField(field.id, value);
      setValue('');
      // Clear the content of the span
      spanRef.current.textContent = '';
    }
  };

  const handleSpanClick = () => {
    if (spanRef.current) {
      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(spanRef.current);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  };

  return (
    <React.Fragment>
      <div className="field task-form-left-side">

        <div className="mb-10">
          <div className="display-flex gap-8">
            <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
              <a href={decoratedHref} key={key} target="_blank">{decoratedText}</a>
            )}>
              {field.label}
            </Linkify>
            {field.isRequired ? <span className="color-red">*</span> : ""}
          </div>
          <p className="font-11">{field.subheading}</p>
        </div>

        {field.options.map((option, index) => (
          <div 
            className="field field-checkbox-option" 
            key={option}
            onClick={(e) => {
              // Only trigger if we're not clicking the input or remove button
              if (!e.target.matches('input, i, .fas')) {
                handleValueAdd(field.id, option, section?.id);
              }
            }}
            style={{ cursor: 'pointer' }}
          >
            <input
              type="checkbox"
              className="mt-0"
              checked={selectedOptions.includes(option)}
              onChange={() => handleValueAdd(field.id, option, section?.id)}
            />
            
            {!taskForm ? (
              <React.Fragment>
                <input 
                  className="daas-task-form-checkbox-empty color-black-always" 
                  defaultValue={option} 
                  onBlur={(e) => handleRadioCheckboxOptionChange(
                    field.id, 
                    index,
                    e.target.value, 
                    section?.id
                  )}
                  onClick={(e) => e.stopPropagation()} // Prevent div click when editing
                />
                <i 
                  className="fas fa-times color-red opacity-4 opacity-10-hover animate cursor-pointer" 
                  style={{paddingLeft: "10px", paddingRight: "10px"}} 
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent div click when removing
                    removeOption(field.id, option, section?.id);
                  }} 
                />
              </React.Fragment>
            ):(
              <span className="ml-10 color-black-always">{option}</span>
            )}
          </div>
        ))}

        {!taskForm && 
          <div className="field daas-task-form-checkbox-wrapper">
            <button 
              className="btn btn-small btn-secondary" 
              onClick={() => addOptionToField(field.id, `New Option ${field.options.length + 1}`, section?.id)}
            >
              <i className="fas fa-plus mr-5"></i>
              Add Option
            </button>
          </div>  
        }
      </div>
    </React.Fragment>
  );
};

export default CheckboxGroup;
