import React , { useState, useContext } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import axios from "axios"; 
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import {ProjectContext} from "../../../../Projects/ProjectContext.js";
import localforage from 'localforage';

const Move = ({task_id}) => {
  const {projects, groups} = useContext(ProjectContext);
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [disabled, setDisabled] = useState(false);
  const [columns, setColumns] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedColumn, setSelectedColumn] = useState(null);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.post(`/api/portal/projects/${selectedProject?.value}/tasks/${task_id}/move`, {
      project_id: selectedProject?.value,
      column_id: selectedColumn?.value
    })
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        localforage.removeItem(`task-show-${task_id}-v2`).then(() => {
          history.push(`/o/${match.params.organization_id}/projects/${selectedProject?.value}/tasks`);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const projectsOptions = [
    ...groups.map((group) => ({
      label: group.title,
      options: group.projects.map((project) => ({
        value: project.token,
        label: project.title,
      })),
    })),
    {
      label: "Clients",
      options: projects.map((project) => {
        // Handle nested projects
        const nestedOptions = project.nested_projects?.map(nested => ({
          value: nested.token,
          label: `- ${nested.title}`,
        })) || [];

        return [
          {
            value: project.token,
            label: project.title,
          },
          ...nestedOptions
        ];
      }).flat(),
    },
  ];

  const columnsOptions = columns.map((item) => ({
    value: item.token,
    label: item.title,
  }));

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'white' : 'black', // Text color of options
    }),
  };

  const handleProjectSelect = (selectedOption) => {
    setSelectedColumn(null);

    if(selectedOption.value == null){
      setSelectedProject(null)
    } else {
      setSelectedProject(selectedOption);
      axios.get(`/api/portal/projects/${selectedOption.value}/columns.json`)
      .then(function (response) {
        // console.log(response);
        if (response.data.success) {
          setColumns(response.data.columns);
        }
      })
      .catch(function (error) {
        console.log(error)
        notice("An error occurred")
      })
      .then(function () {
        // always executed
      });
    }
  };

  const handleColumnSelect = (selectedOption) => {
    if(selectedOption.value == null){
      setSelectedColumn(null)
    } else {
      setSelectedColumn(selectedOption);
    }
  };

  return(
    <React.Fragment>
      <h4 className="mb-25">Which client should we move it to?</h4>
      <form onSubmit={handleSubmit}>
        <div className="field">
          <label>Client</label>
          <Select
            options={projectsOptions}
            value={selectedProject}
            onChange={handleProjectSelect}
            className="color-1"
            classNamePrefix="react-select"
            placeholder="Select..."
            styles={customStyles} 
          />
        </div>

        {selectedProject && 
          <div className="field animated fadeInUp">
            <label>Column</label>
            <Select
              options={columnsOptions}
              value={selectedColumn}
              onChange={handleColumnSelect}
              className="color-1"
              classNamePrefix="react-select"
              placeholder="Select..."
              styles={customStyles} 
            />
          </div>
        }
        
        {selectedColumn &&
        <>
          <div className="field text-right">
            <button disabled={disabled || !selectedProject || !selectedColumn} type="submit" className="btn btn-primary">
              {t("Move")}
            </button>
          </div>
        </>
        }
      </form>
    </React.Fragment>
  )
}

export default Move;