import React, {useState, useRef, useContext} from 'react';
import Linkify from 'react-linkify';
import { TaskFormContext } from './TaskFormContext.js';

const RadioGroup = ({ field, selectedOption, onChange, removeField, taskForm, handleLabelChange, section }) => {
  const {addOptionToField, removeOption, handleRadioCheckboxOptionChange, handleRadioChange} = useContext(TaskFormContext);
  const [value, setValue] = useState('');
  const spanRef = useRef(null);

  const handleKeyPress = (e) => {
    setValue(spanRef.current.textContent);

    if (e.key === 'Enter') {
      e.preventDefault();
      addOptionToField(field.id, value, section?.id);
      setValue('');
      spanRef.current.textContent = '';
    }
  };

  const handleOptionChange = (option) => {
    handleRadioChange(field.id, option, section?.id);
  };

  return (
    <React.Fragment>
      <div className="field task-form-left-side">
        <div className="mb-10">
          <div className="display-flex gap-8">
            <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
              <a href={decoratedHref} key={key} target="_blank">{decoratedText}</a>
            )}>
              {field.label}
            </Linkify>
            {field.isRequired ? <span className="color-red">*</span> : ""}
          </div>
          <p className="font-11">{field.subheading}</p>
        </div>

        {field.options.map((option, index) => (
          <div 
            className="field field-checkbox-option" 
            key={option}
            onClick={(e) => {
              if (!e.target.matches('input, i, .fas')) {
                handleOptionChange(option);
              }
            }}
            style={{ cursor: 'pointer' }}
          >
            <input
              type="radio"
              style={{display: "inline", margin: 0}}
              checked={selectedOption === option}
              onChange={() => handleOptionChange(option)}
              value={option}
            />
            {!taskForm ? (
              <React.Fragment>
                <input 
                  className="daas-task-form-checkbox-empty color-black-always" 
                  defaultValue={option} 
                  onBlur={(e) => handleRadioCheckboxOptionChange(field.id, index, e.target.value, section?.id)}
                  onClick={(e) => e.stopPropagation()}
                />
                <i 
                  className="fas fa-times color-red opacity-4 opacity-10-hover animate cursor-pointer" 
                  style={{paddingLeft: "10px", paddingRight: "10px"}} 
                  onClick={(e) => {
                    e.stopPropagation();
                    removeOption(field.id, option, section?.id);
                  }} 
                />
              </React.Fragment>
            ):(
              <span className="ml-10 color-black-always">{option}</span>
            )}
          </div>
        ))}

        {!taskForm && 
          <div className="field daas-task-form-checkbox-wrapper">
            <button 
              className="btn btn-small btn-secondary" 
              onClick={() => addOptionToField(field.id, `New Option ${field.options.length + 1}`, section?.id)}
            >
              <i className="fas fa-plus mr-5"></i>
              Add Option
            </button>
          </div>
        }
      </div>
    </React.Fragment>
  );
};

export default RadioGroup;
