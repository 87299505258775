import React, { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import axios from "axios";
import { Modal } from 'react-responsive-modal';
import localForage from "localforage";
import FullCard from "../Daas/Portal/Projects/FullCard.js";
import NoDeadlineTasks from "./NoDeadlineTasks.js";
import Loading from "../Shared/Loading.js";
// import "./styles.css";
import NewLabel from "../Labels/NewLabel.js";
import { TaskboardContext } from '../Shared/TaskboardContext.js';

const localizer = momentLocalizer(moment);
const DragAndDropCalendar = withDragAndDrop(Calendar);

const MyCalendar = ({height, refetchData}) => {
  const match = useRouteMatch();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState([]);
  const [noDeadlineTasks, setNoDeadlineTasks] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const [openAddTask, setOpenAddTask] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [openNewLabel, setOpenNewLabel] = useState(false);
  const [clientView, setClientView] = useState(typeof window !== 'undefined' && window.location.href.includes("/portal/o/"))
  const { columns, setColumns } = useContext(TaskboardContext);

  useEffect(() => {
    const allTasks = columns.flatMap(column => column.tasks);
    
    // Tasks with start dates go to events
    const tasksWithDates = allTasks
      .filter(task => task.start)
      .map(task => ({
        ...task,
        start: new Date(task.start),
        end: new Date(task.end || task.start),
      }));
    
    // Tasks without start dates go to noDeadlineTasks
    const tasksWithoutDates = allTasks.filter(task => !task.start);
    
    setEvents(tasksWithDates);
    setNoDeadlineTasks(tasksWithoutDates);
    setLoading(false);
  }, [columns]);

  const handleSelectSlot = ({ start, end }) => {
    setStartDate(start)
    setEndDate(end)
    setOpenAddTask(true)
  };

  const handleSelectEvent = (event) => {
    setOpen(true);
    setSelectedTask(event);
    // alert(`Event: ${event.title}`);
    // console.log(event);
    // Your custom function logic here
  };

  const moveEvent = ({ event, start, end }) => {
    const updatedEvent = { ...event, start, end };
    
    // If start and end are on consecutive days at midnight, make end same as start
    if (start.getHours() === 0 && end.getHours() === 0 && 
        end.getTime() - start.getTime() === 24 * 60 * 60 * 1000) {
      end = new Date(start);
    }

    const nextEvents = events.map((existingEvent) =>
      existingEvent.title === event.title ? updatedEvent : existingEvent
    );

    setEvents(nextEvents);
    updateEvent(start, end, event.token, event.project?.token);
  };

  const updateEvent = (startDate, deadline, task_id, projectId) => {
    // Convert dates to UTC ISO strings
    const utcStart = new Date(startDate).toISOString();
    const utcDeadline = new Date(deadline).toISOString();

    axios.put(`/api/portal/projects/${projectId}/tasks/${task_id}`, {
      start_date: utcStart,
      deadline: utcDeadline
    })
    .then(function(response){
      if(response.data.success){
        // Update the task in columns
        // console.log(response.data.task_data);
        setColumns(prevColumns => {
          return prevColumns.map(column => {
            if (column.title === response.data.task_data.column.title) {
              return {
                ...column,
                tasks: column.tasks.map(t => 
                  t.token === response.data.task_data.token ? response.data.task_data : t
                )
              };
            }
            return column;
          });
        });
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    });
  }

  const resizeEvent = ({ event, start, end }) => {
    // Adjust end date to be 23:59:59 of the same day if it's midnight of next day
    if (end.getHours() === 0 && end.getMinutes() === 0) {
      end = new Date(end);
      end.setDate(end.getDate() - 1);
      end.setHours(23, 59, 59);
    }

    const updatedEvent = { ...event, start, end };
    const nextEvents = events.map((existingEvent) =>
      existingEvent.title === event.title ? updatedEvent : existingEvent
    );
    setEvents(nextEvents);

    updateEvent(start, end, event.token, event.project?.token);
  };

  const eventStyleGetter = (event, start, end, isSelected) => {
    let backgroundColor = '#3174ad'; // Default color

    // Set color based on priority
    switch (event.priority) {
      case 'High':
        backgroundColor = '#dc3545'; // High priority color
        break;
      case 'Medium':
        backgroundColor = '#ffab58'; // Medium priority color
        break;
      case 'Low':
        backgroundColor = '#4169e1'; // Low priority color
        break;
      default:
        break;
    }

    // Override color for finished events
    if (event.finished) {
      backgroundColor = '#00c292'; // Gray color for finished events
    }

    return {
      style: {
        backgroundColor,
        borderRadius: '0px',
        opacity: 0.8,
        color: 'white',
        border: '0px',
        display: 'block'
      }
    };
  };

  return (
    <div className="mt-15">
      {loading && 
        <Loading loading={loading} />
      }
      <DragAndDropCalendar
        localizer={localizer}
        events={events.filter(event => {
                          // new filter to exclude private cards if the URL includes "/portal/o/"
                          if (event.private && clientView) {
                            return false; // exclude this card
                          }
                          return true; // include this card
                        })}
        startAccessor="start"
        endAccessor="end"
        style={{ height: height }}
        selectable
        onSelectSlot={handleSelectSlot}
        onSelectEvent={handleSelectEvent}
        onEventDrop={moveEvent}
        resizable
        onEventResize={resizeEvent} 
        eventPropGetter={eventStyleGetter} // Apply custom styles
        popup={true}
      />

      <Modal focusTrapped={false} closeOnEsc={false} open={open} onClose={() => {setOpen(false); setSelectedTask(null)}} className="modal-body-white" classNames={{modal: 'modal-side'}} center>
        {selectedTask && 
          <FullCard task_id={selectedTask.token} refetchData={refetchData} organizationUser={true} project_id={selectedTask.project?.token}/>
        }
      </Modal>
      
      <Modal open={openAddTask} onClose={() => {setOpenAddTask(false);}} className="modal-body-white" classNames={{modal: 'modal-calendar-add-task'}} center>
        <NoDeadlineTasks tasks={noDeadlineTasks} start={startDate} end={endDate} closeModal={() => setOpenAddTask(false)} setEvents={setEvents}/>
      </Modal>

      <Modal open={openNewLabel} onClose={() => setOpenNewLabel(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
        <NewLabel closeModal={() => setOpenNewLabel(false)}/>
      </Modal>
    </div>
  );
};

export default MyCalendar;
