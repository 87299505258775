import React , { useState, useEffect, useContext } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { useTranslation } from 'react-i18next';
import {OrganizationContext} from "../../../../Shared/OrganizationContext.js";
import {ProjectContext} from "../../../../Projects/ProjectContext.js";
import PrivateForm from "./PrivateForm.js";
import Select from "react-select";
import TipTap from "../../../../Shared/TipTap.js";

const DefaultQuestionsUniversal = ({closeModal}) => {
  const {organizationAuthorizations, hasPermission} = useContext(OrganizationContext);
  // const currentUser = useContext(UserContext);
  const {projects, setRefreshData, refreshData, setNewTasks} = useContext(ProjectContext);
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [priority, setPriority] = useState("");
  const [selectedProject, setSelectedProject] = useState(null);
  const [columns, setColumns] = useState([]);
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [isPrivate, setIsPrivate] = useState(false);
  const [priorities, setPriorities] = useState([{label: "No priority", value: null}, {label: "High", value: "High"}, {label: "Medium", value: "Medium"}, {label: "Low", value: "Low"}]);
  const [selectedPriority, setSelectedPriority] = useState(null);
  const [openFilepicker, setOpenFilepicker] = useState(false);
  const [advanced, setAdvanced] = useState(false);
  const [selectedTaskLabels, setSelectedTaskLabels] = useState([]);
  const [selectedTaskUsers, setSelectedTaskUsers] = useState([]);
  const [deadline, setDeadline] = useState(null);
  
  useEffect(() => {
    setColumns([]);
    if(selectedProject){
      fetchColumns();
    }
  }, [selectedProject]);

  const fetchColumns = () => {
    axios.get(`/api/portal/projects/${selectedProject?.value}/columns.json`)
    .then(function(response){
    //   console.log(response);
      if(response.data.success){
        setColumns(response.data.columns);
        if(response.data.columns.length > 0){
          setSelectedColumn({label: response.data.columns[0].title, value: response.data.columns[0].token});
        }
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.post(`/api/portal/projects/${selectedProject?.value}/tasks`, {
      column_id: selectedColumn.value,
      title: title, 
      description: description, 
      priority: selectedPriority?.value, 
      // date: date, 
      // parent_task_id: parent_task_id, 
      // attachements: attachements, 
      private: isPrivate, 
      // selected_task_labels: selectedTaskLabels,
      // selected_task_users: selectedTaskUsers, 
      // deadline: deadline
    })
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        notice("Task created successfully");
        setRefreshData(refreshData + 1)
        closeModal();
        // setNewTasks(prev => [...prev, { id: Date.now(), title: "Your task title here" }]);
        setNewTasks(prev => [...prev, { id: Date.now(), title: title, project_id: selectedProject.value, task_id: response.data.task.token }]);
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  };


  return(
    <React.Fragment>
      <h4 className="color-black-always modal-title">New request</h4>
      <div className="row">
        <form onSubmit={handleSubmit}>
          <div className="field">
            <label>{t("Title")}</label>
            <input value={title} onChange={(e) => setTitle(e.target.value)} className="form-control" required="true" autoFocus/>
          </div>

          <div className="field">
            <label>{t("Description")}</label>
            <TipTap comment={description} setComment={setDescription} users={[]}/>
          </div>

          <div className="field">
            <label>{t("Client")}</label>
            <Select
              isMulti={false}
              options={projects.map(p => ({label: p.title, value: p.token}))}
              value={selectedProject}
              onChange={(selectedOptions) => setSelectedProject(selectedOptions)}
              isDisabled={disabled}
              className="color-1"
              classNamePrefix="react-select"
              placeholder="Select Client..."
            />
          </div>

          {columns.length > 0 && 
            <div className="field">
              <label>{t("Column")}</label>
              <Select
                isMulti={false}
                options={columns.map(c => ({label: c.title, value: c.token}))}
                value={selectedColumn}
                onChange={(selectedOptions) => setSelectedColumn(selectedOptions)}
                isDisabled={disabled}
                className="color-1"
                classNamePrefix="react-select"
                placeholder="Select column..."
              />
            </div>
          }

          <div className="field text-right mt-15 display-flex align-items-center justify-content-end">
            <a onClick={() => setOpenFilepicker(true)} className="color-1 dark-color-black"><i className="fas fa-paperclip mr-15"></i></a>

            <div className="mr-15" style={{minWidth: 70}}>
              <Select
                menuPlacement="top"
                isMulti={false}
                options={priorities}
                value={selectedPriority}
                onChange={(selectedOption) => setSelectedPriority(selectedOption)}
                className="color-1 react-select-small"
                classNamePrefix="react-select"
                placeholder="Priority"
                components={{ DropdownIndicator: null }}
              />
            </div>
            
            {(organizationAuthorizations.organization_user) && 
              <PrivateForm isPrivate={isPrivate} setIsPrivate={setIsPrivate} />
            }
            
            <button disabled={disabled || !selectedProject || !selectedColumn} className="btn btn-primary" type="submit">{t("Submit")}</button>
          </div>
        </form>
      </div>
    </React.Fragment>
  )
}

export default DefaultQuestionsUniversal;