import React, { useContext } from 'react';
import {OrganizationContext} from "../Shared/OrganizationContext.js";

const SubtaskItem = ({ subtask, index, setSubtasks, subtasks }) => {
  const {organizationAuthorizations, organizationUsers, labels} = useContext(OrganizationContext);
  const removeSubtask = (index) => {
    setSubtasks(subtasks.filter((_, i) => i !== index));
  };

  const updateSubtaskTitle = (index, newTitle) => {
    setSubtasks(subtasks.map((subtask, i) => 
      i === index ? { ...subtask, title: newTitle } : subtask
    ));
  };

  const toggleLabel = (labelId, e) => {
    e.stopPropagation();
    setSubtasks(subtasks.map((s, i) => {
      if (i === index) {
        const currentLabels = s.labels || [];
        const label = labels.find(l => l.id === labelId);
        const hasLabel = currentLabels.some(l => l.id === labelId);
        return {
          ...s,
          labels: hasLabel
            ? currentLabels.filter(l => l.id !== labelId)
            : [...currentLabels, label]
        };
      }
      return s;
    }));
  };

  const toggleUser = (userId, e) => {
    e.stopPropagation();
    setSubtasks(subtasks.map((s, i) => {
      if (i === index) {
        const currentUsers = s.users || [];
        const hasUser = currentUsers.some(u => u.id === userId);
        const user = organizationUsers.find(u => u.user.id === userId)?.user;
        return {
          ...s,
          users: hasUser
            ? currentUsers.filter(u => u.id !== userId)
            : [...currentUsers, user]
        };
      }
      return s;
    }));
  };

  const subtask_labels_list = subtask?.labels?.map(label => (
    <span key={label.id} className="badge" style={{backgroundColor: `#${label.color}`}}>{label.title}</span>
  ));

  const subtask_users_list = (subtask?.users || []).map(user => (
    <span key={user.id} className="badge-user ml-15-negative">
      <img src={user.avatar} className="avatar-small mr-5" />
    </span>
  ));

  return (
    <div key={index} className="task-template-subtask d-flex align-items-center">
      <div className="subtask-drag-handle">
        <i className="fas fa-grip-vertical opacity-4 mr-15"></i>
      </div>
      <i className="fal fa-circle"></i>
      <input
        type="text"
        className="form-control flex-grow-1 mr-15 border-none background-none width-100"
        placeholder="Subtask title"
        value={subtask.title}
        onChange={(e) => updateSubtaskTitle(index, e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            // Only add new subtask if current one isn't empty
            if (subtask.title.trim()) {
              setSubtasks([...subtasks, { title: "", labels: [], users: [] }]);
              // Focus the new input after React re-renders
              setTimeout(() => {
                const inputs = document.querySelectorAll('.task-template-subtask input');
                inputs[inputs.length - 1]?.focus();
              }, 0);
            }
          } else if (e.key === 'Backspace' && !subtask.title && subtasks.length > 1) {
            // Remove empty subtask when pressing backspace
            e.preventDefault();
            removeSubtask(index);
            // Focus previous input
            setTimeout(() => {
              const inputs = document.querySelectorAll('.task-template-subtask input');
              inputs[index - 1]?.focus();
            }, 0);
          }
        }}
      />
      <div className="btn-group">
        <a type="button" className="opacity-4 opacity-10-hover btn background-hover color-1 btn-small dropdown-toggle" data-toggle="dropdown">
          <i className="far fa-ellipsis-v"></i>
        </a>
        <ul className="dropdown-menu animated fadeInUp" role="menu">
          <li className="dropdown-submenu">
            <a tabIndex="-1"><i className="fal fa-tag"></i>Labels</a>
            <ul className="dropdown-menu animated fadeInUp">
              {labels.map(label => (
                <li key={label.id}>
                  <a onClick={(e) => toggleLabel(label.id, e)}>
                    <i className={`far ${(subtask.labels || []).some(l => l.id === label.id) ? 'fa-check-square' : 'fa-square'}`}></i>
                    <span className="ml-5" style={{color: label.color}}>{label.title}</span>
                  </a>
                </li>
              ))}
            </ul>
          </li>
          <li className="dropdown-submenu">
            <a tabIndex="-1"><i className="fal fa-user"></i>Assign Users</a>
            <ul className="dropdown-menu animated fadeInUp">
              {organizationUsers.map(organization_user => (
                <li key={organization_user.user.id}>
                  <a onClick={(e) => toggleUser(organization_user.user.id, e)}>
                    <i className={`far ${(subtask.users || []).some(u => u.id === organization_user.user.id) ? 'fa-check-square' : 'fa-square'}`}></i>
                    <img src={organization_user.user.avatar} className="avatar-small mr-5" />
                    <span className="ml-5">{organization_user.user.username}</span>
                  </a>
                </li>
              ))}
            </ul>
          </li>
          <li><a onClick={() => removeSubtask(index)}><i className="fal fa-trash-alt"></i>Remove</a></li>
        </ul>
      </div>

      <div className="task-template-subtask-labels display-flex align-items-center">
        {subtask_labels_list}
        <div className="ml-15">
          {subtask_users_list}
        </div>
      </div>
    </div>
  );
};

export default SubtaskItem; 