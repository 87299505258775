import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import TaskForms from "../TaskForms.js";
import TipTap from "../../../Shared/TipTap.js";
import finishedData from "../../../Shared/Lotties/lottie-finished.json";
import { Player } from "@lottiefiles/react-lottie-player";
import { UserContext } from "../../../Shared/UserContext.js";
import Signin from "../../../CodeAuth/Signin.js";
import { AppContext } from "../../../Shared/AppContext.js";

const ShareTaskForm = ({}) => {
  // const {organizationAuthorizations} = useContext(OrganizationContext);
  const currentUser = useContext(UserContext);
  const {checkLogin} = useContext(AppContext);
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [questionsSections, setQuestionsSections] = useState([]);
  const [attachements, setAttachements] = useState([]);
  const [isPrivate, setIsPrivate] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [priority, setPriority] = useState("");
  const [date, setDate] = useState("");
  const [deadline, setDeadline] = useState("");
  const [animationComplete, setAnimationComplete] = useState(false);

  useEffect(() => {
    fetchProjectData();
  }, []);

  useEffect(() => {
    if (submitted) {
      // Reset animation state when form is submitted
      handleAnimationComplete()
    }
  }, [submitted]);

  const fetchProjectData = () => {
    axios.get(`/api/service_forms/${match.params.service_form_id}.json?project_id=${match.params.project_id}`)
    .then(function(response){
    //   console.log(response);
      if(response.data.success){
        setData(response.data.data);
        setLoaded(true);
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.post(`/api/portal/projects/${data?.project_id}/tasks`, {
      column_id: data?.column?.token,
      title: title, 
      description: description, 
      priority: priority, 
      date: date,
      questions: questions,
      section_field_data: questionsSections,
      attachements: attachements, 
      private: isPrivate, 
      service_form_id: data?.chosen_service_form?.token, 
      deadline: deadline
    })
    .then(function(response){
      if(response.data.success){
        setSubmitted(true);
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const handlePriorityChange = (event) => {
    setPriority(event.target.value);
  };
  
  const handleAnimationComplete = () => {
    notice("Finished!");
    // Set a timeout to show the button after 3 seconds
    setTimeout(() => {
      setAnimationComplete(true);
    }, 3000);
  };

  return(
    <React.Fragment>
      {!currentUser && (
        <div className="task-form-signin-container">
          <Signin refetchData={() => {checkLogin()}} />
        </div>
      )}
      {loaded && (
        <React.Fragment>
          {submitted ? ( 
            <div className="display-flex align-items-center justify-content-center flex-direction-column" style={{height: "100vh"}}>
              <Player 
                style={{width: "300px", margin: "auto"}} 
                mode="normal" 
                autoplay 
                loop={false} 
                src={finishedData}
              />
              <br />
              {animationComplete && (
                <button className="btn btn-default border-all" onClick={() => {setSubmitted(false)}}>Submit another</button>
              )}
            </div>
          ):(
            <div className="container pt-30" style={{maxWidth: "800px"}}>
              
              <form onSubmit={handleSubmit}>
                <div className="field">
                  <div className="form-section">
                    <div className="form-header-container">
                      <div className="display-flex flex-direction-column header-title-wrapper ml-10">
                        <div className="form-header-input" style={{marginLeft: -5}}>
                          {data.chosen_service_form.title}
                        </div>
                      </div>
                    </div>

                    <div className="field">
                      <label>{t("Title")}</label>
                      <input value={title} onChange={(e) => setTitle(e.target.value)} className="form-control" required="true"/>
                    </div>

                    {!data.chosen_service_form.disable_description && 
                      <div className="field">
                        <label>{t("Description")}</label>
                        <TipTap comment={description} setComment={setDescription} users={[]}/>
                      </div>
                    }

                    {!data.chosen_service_form.disable_priority && 
                      <div className="field row">
                        <div className="col-12">
                        <label htmlFor="priority">{t("Priority")}</label>
                        <select className="form-control" id="priority" value={priority} onChange={handlePriorityChange}>
                          <option value="">Select priority</option>
                          <option value="High">High</option>
                          <option value="Medium">Medium</option>
                          <option value="Low">Low</option>
                        </select>
                        </div>
                      </div>
                    }
                  </div>
                </div>


                <div className="field">
                  <TaskForms refetchData={() => {setSubmitted(true)}} chosenServiceForm={data.chosen_service_form} edit={false} taskForm={true} services={[]} setQuestions={setQuestions} setQuestionsSections={setQuestionsSections} />
                </div>

                <div className="field text-right">
                  <button disabled={disabled} type="submit" className="btn btn-primary" onClick={handleSubmit}>Submit</button>
                </div>
              </form>
            </div>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default ShareTaskForm;