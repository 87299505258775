import React , { useState, useEffect, useContext } from 'react';
import { useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import {ProjectContext} from "../Projects/ProjectContext.js";
import ActiveTimer from "./ActiveTimer.js";

const TaskTimeTracker = ({task, project_id}) => {
  const {fetchTimesheets, timesheets} = useContext(ProjectContext);
  const match = useRouteMatch();
  const [disabled, setDisabled] = useState(false);
  const [timerActive, setTimerActive] = useState(false);
  const [activeTimesheet, setActiveTimesheet] = useState(null);

  useEffect(() => {
    const matchingTimesheet = timesheets.find(timesheet => timesheet.task.token === task.token && (timesheet.finished === null || timesheet.finished === false));
    const isActive = !!matchingTimesheet; // Convert to boolean
    setTimerActive(isActive);
    
    // If a matching timesheet is found, update activeTimesheet state
    if (isActive) {
      setActiveTimesheet(matchingTimesheet);
    } else {
      setActiveTimesheet(null); // Reset activeTimesheet state if no matching timesheet
    }
  }, [task.token, timesheets]);


  const handleSubmit = () => {
    setDisabled(true);

    axios.post(`/api/o/${match.params.organization_id}/timesheets`, {
      task_id: task.token,
      project_id: project_id, 
      start_time: new Date().toISOString(),
      is_active: true, 
      finished: false
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        fetchTimesheets(); 
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  }


  return(
    <React.Fragment>
      {timerActive ? (
        <ActiveTimer refetchData={fetchTimesheets} timesheet={activeTimesheet} taskView={true}/>
      ):(
      <a onClick={handleSubmit} className="btn btn-small btn-primary-outline mr-10 task-timer-button hover-parent-opacity" data-tip="Track your time spent on this task.">
        <i className="fal fa-clock task-comment-replay-icon"></i>
        Start timer
      </a>
      )}
    </React.Fragment>
  )
}

export default TaskTimeTracker;