import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import _ from 'lodash'; // setClients(prevState => _.reject(prevState, { token: client.token }));
// import {OrganizationContext} from "../../Shared/OrganizationContext.js";
// import {UserContext} from "../../Shared/UserContext.js";
import CreatableSelect from 'react-select/creatable';

const CategoryForm = ({refetchData, serviceForm, categories, setCategories, selectedCategories, setSelectedCategories}) => {
  // const {organizationAuthorizations} = useContext(OrganizationContext);
  // const currentUser = useContext(UserContext);
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    // If serviceForm exists (editing mode), populate categories
    if (serviceForm?.service_form_categories) {
      setSelectedCategories(
        serviceForm.service_form_categories.map(category => ({
          value: category.token,
          label: category.title
        }))
      );
    }
  }, [serviceForm]);

  const handleCategorySelect = (selectedOptions) => {
    // Convert to empty array if null
    const newSelectedCategories = selectedOptions || [];
    
    // Find added and removed categories by comparing with previous state
    const added = newSelectedCategories.filter(option => 
      !selectedCategories.find(cat => cat.value === option.value)
    );
    
    const removed = selectedCategories.filter(option => 
      !newSelectedCategories.find(cat => cat.value === option.value)
    );

    if (serviceForm != null) {
      // Handle added categories
      added.forEach(category => {
        axios.post(`/api/o/${match.params.organization_id}/service_forms/${serviceForm.token}/service_form_categories`, {
        category_id: category.value
        })
        .then(response => {
          if (response.data.success) {
            notice("Category added successfully");  
            refetchData();
          }
        })
        .catch(error => {
          console.error('Error adding category:', error);
          notice("Failed to add category");
        });
      });

      // Handle removed categories
      removed.forEach(category => {
        axios.delete(`/api/o/${match.params.organization_id}/service_forms/${serviceForm.token}/service_form_categories/${category.value}`)
        .then(response => {
          if (response.data.success) {
            notice("Category removed successfully");
            refetchData();
          }
        })
        .catch(error => {
          console.error('Error removing category:', error);
          notice("Failed to remove category");
        });
      });
    }

    // Update local state
    setSelectedCategories(newSelectedCategories);
  };

  const handleCreateCategory = (inputValue) => {
    axios.post(`/api/o/${match.params.organization_id}/categories`, { title: inputValue })
    .then(response => {
      if (response.data.success) {
        refetchData();
      }
    })
    .catch(error => {
      console.error('Error creating category:', error);
      notice("Failed to create category");
    });
  }

  const categoryOptions = categories
    ?.filter(category => 
      !selectedCategories.some(selected => selected.label === category.title)
    )
    .map(category => ({
      value: category.token,
      label: category.title
    }));

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'white' : 'black', // Text color of options
    }),
  };

  return(
    <React.Fragment>
      <label>Category <span className="font-12 opacity-6">- Add this form to a category to organize it</span></label>
      <CreatableSelect
        options={categoryOptions}
        value={selectedCategories}
        onChange={handleCategorySelect}
        onCreateOption={handleCreateCategory}
        className="color-1"
        classNamePrefix="react-select"
        placeholder="Select or type to create..."
        styles={customStyles} 
        isMulti={true}
      />
    </React.Fragment>
  )
}

export default CategoryForm;