import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import "@lottiefiles/lottie-player";
import {UserContext} from "../../Shared/UserContext.js";
import Pusher from 'pusher-js';
import StripeAccount from "./StripeAccount.js";
import animationData from "../../Shared/Lotties/lf20_pghai0vg.json";
import { Player } from "@lottiefiles/react-lottie-player";
import Paypal from "../PaypalAccounts/Paypal.js";
import PaypalAccount from "../PaypalAccounts/PaypalAccount.js";
import {OrganizationContext} from "../../Shared/OrganizationContext.js";
import finishedData from "../../Shared/Lotties/lottie-finished.json";
import ReactTooltip from 'react-tooltip';

const Stripe = ({onboarding, title, description, refetchData, onboardingStripeAccount}) => {
  const {organizationAuthorizations} = useContext(OrganizationContext);
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [loadedTwo, setLoadedTwo] = useState(false);
  const currentUser = useContext(UserContext);
  const [stripeAccount, setStripeAccount] = useState(organizationAuthorizations.organization.stripe_account);
  const [paypalAccount, setPaypalAccount] = useState(organizationAuthorizations.organization.paypal_account);

  useEffect(() => {
    // fetchStripeAccounts();
    // fetchPaypalAccount();

    // Create a Pusher client
    const stripeAccountsPusher = new Pusher('683f89863cff332fae20', {
      cluster: 'us2',
      encrypted: true
    });

    // Subscribe to a channel
    const channel = stripeAccountsPusher.subscribe(`${match.params.organization_id}-stripe-accounts`);

    // Bind to an event on the channel
    channel.bind('channel', data => {
      console.log(data);
      fetchStripeAccounts();
      refetchData();
    });

    // Unsubscribe from the channel when the component unmounts
    return () => {
      channel.unsubscribe();
    };
  },[])


  const fetchStripeAccounts = () => {
    axios.get(`/api/o/${match.params.organization_id}/stripe_accounts.json`)
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        setStripeAccount(response.data.stripe_account);
      } else {
        notice(response.data.error);
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setLoaded(true)
    });
  }

  const fetchPaypalAccount = () => {
    axios.get(`/api/o/${match.params.organization_id}/paypal_accounts.json`)
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        setPaypalAccount(response.data.paypal_account);
      } else {
        notice(response.data.error);
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setLoadedTwo(true)
    });
  }


  return(
    <React.Fragment>
      {(stripeAccount == null && paypalAccount == null) ? ( 
        <React.Fragment>
          <div className={`background-1 dark-background-white default-padding border-radius ${onboarding == null ? "border-all" : ""}`}>
            <div className="setup-stripe-block">
              <Player style={{width: "300px", margin: "auto"}} mode="normal" autoplay loop src={animationData} />
              {
                onboarding == null && 
                <React.Fragment>
                  <h4 className="color-black-always">{title}</h4>
                </React.Fragment>
              }
              <ReactTooltip effect="solid" backgroundColor="black" textColor="white" delayShow={200}/>
              <a style={{backgroundColor: "#5433FF"}} className="btn btn-primary mt-15" href={`https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${currentUser.stripe_setup.client_id}&scope=read_write&redirect_uri=${currentUser.stripe_setup.redirect_url}&state=${match.params.organization_id}`} target="blank">
                <i class="fab fa-stripe-s mr-8"></i>Stripe (Recommended)
                <i className="far fa-question-circle ml-5" data-tip="Stripe is required for certain features like Referral Program, Add-ons, upgrades, and retention offers."></i>
              </a>

              <Paypal refetchData={fetchPaypalAccount}/>
            </div>
          </div>
        </React.Fragment>
      ):(
        onboarding == null ?(
          <div className="background-1 default-padding border-radius border-all background-3">
            {stripeAccount &&
              <StripeAccount stripeAccount={stripeAccount} refetchData={fetchStripeAccounts}/>
            }
            {paypalAccount && 
              <>
                <PaypalAccount paypalAccount={paypalAccount} refetchData={fetchPaypalAccount}/>
              </>
            }
          </div>
        ):(
          <div className="background-1 default-padding border-radius">
            <div className="setup-stripe-block">
              {(paypalAccount || stripeAccount) ? (
                <Player style={{width: "300px", margin: "auto"}} mode="normal" autoplay loop src={finishedData} />
              ):(
                <Player style={{width: "300px", margin: "auto"}} mode="normal" autoplay loop src={animationData} />
              )}
            </div>
          </div>
        )
      )}
    </React.Fragment>
  )
}

export default Stripe