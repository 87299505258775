import React, {useState} from 'react';
import { PickerInline } from 'filestack-react';
import { Modal } from 'react-responsive-modal';

const Filestack = ({ field, handleValueChange }) => {
  const [open, setOpen] = useState(false);

  const createReplay = (filestack) => {
    const filesUploaded = filestack.filesUploaded;
    console.log(filesUploaded);
    // const filesData = filesUploaded.map((file) => ({ title: file.name, url: file.url }));
    handleValueChange(field.id, filesUploaded);
};

  

  return (
    <React.Fragment>
      <a onClick={() => setOpen(true)} className="filepicker-inline color-1 opacity-4 opacity-10-hover animate">
        <i class="fal fa-upload" style={{fontSize: "30px"}}></i>
        <div style={{paddingTop: '10px'}}>
          Click to upload file
        </div>
      </a>
      
      <Modal open={open} onClose={() => setOpen(false)} className="modal-body-white" classNames={{modal: 'width-800'}} center>
        <PickerInline
          apikey={"AKVhxMbEQhkOIryqBvSEQz"}
          onSuccess={(res) => console.log(res)}
          onUploadDone={(res) => {
            createReplay(res);
          }}
          clientOptions={{
            sessionCache: true
          }}
          pickerOptions={{
            onClose: () => {
              setOpen(false);
            }, 
            fromSources: ["local_file_system", "googledrive", "dropbox", "box", "onedrive", "onedriveforbusiness", "googlephotos", "facebook", "instagram"],
            maxFiles: 15, 
            storeTo: {
              location: 'gcs',
              path: '/',
              container: 'queue-filestack'
            }
          }}
        />
      </Modal>
    </React.Fragment>
  );
}

export default Filestack;